<div class="menu">
    <ul id="menu-list">
        <li><a href="https://www.greif-velox.com/"><div class="logo"></div></a></li>
        <li class="smallHide"><a href="tel:+4945153030" ><div id="phone-item">0451 53 03 0</div></a></li>
        <li class="smallHide"><a href="https://www.greif-velox.com/produkte/fluessigkeitsabfuellung/paletten-abfuellanlagen/">{{_rdosService._rdosConfig.pallets}}</a></li>
        <li class="smallHide"><a href="https://www.greif-velox.com/produkte/fluessigkeitsabfuellung/kanister-abfuellanlagen/">{{_rdosService._rdosConfig.canister}}</a></li>
        <li class="smallHide"><a href="https://www.greif-velox.com/produkte/fluessigkeitsabfuellung/fassabfuellanlagen/">{{_rdosService._rdosConfig.barrels}}</a></li>
        <li class="smallHide"><a href="https://www.greif-velox.com/produkte/fluessigkeitsabfuellung/ibc-abfuellanlagen/">{{_rdosService._rdosConfig.ibc}}</a></li>


        <li class="language">
            <ng-container *ngIf="language== 'en'; else germanTemplate">
                <button  class="language_button flag-button"(focus)="openLanguages()" (focusout)="closeLanguages()">
                    <span class="british_flag" ><img class="flag" src="../../assets/british_flag.jpg"></span>
                </button>
                <button class="float-bottom flag-button" *ngIf="showOtherFlag == true" (mousedown)="changeLanguage('de')">
                    <span class="german_flag"><img class="flag" src="../../assets/german_flag.jpg"></span>
                </button>
            </ng-container>
            <ng-template #germanTemplate>
                <button class="language_button flag-button" (focus)="openLanguages()" (focusout)="closeLanguages()">
                    <span class="german_flag"><img class="flag" src="../../assets/german_flag.jpg"></span>
                </button>
                <button  class="float-bottom flag-button" *ngIf="showOtherFlag == true" (mousedown)="changeLanguage('en')">
                    <span class="german_flag " ><img class="flag" src="../../assets/british_flag.jpg"></span>
                </button>
            </ng-template>


        </li>
        <li class="smallShow"><span>
            <img class="hamburger" src="../../assets/hamburger.svg">
            <div class="dropdown-content">
                <ul id="menu-list" class="menu-list2">
                    <a href="https://www.greif-velox.com/produkte/fluessigkeitsabfuellung/paletten-abfuellanlagen/">{{_rdosService._rdosConfig.pallets}}</a>
                    <a href="https://www.greif-velox.com/produkte/fluessigkeitsabfuellung/kanister-abfuellanlagen/">{{_rdosService._rdosConfig.canister}}</a>
                    <a href="https://www.greif-velox.com/produkte/fluessigkeitsabfuellung/fassabfuellanlagen/">{{_rdosService._rdosConfig.barrels}}</a>
                    <a href="https://www.greif-velox.com/produkte/fluessigkeitsabfuellung/ibc-abfuellanlagen/">{{_rdosService._rdosConfig.ibc}}</a>
                </ul>
              </div>
        </span></li>

        <li class="contact"><a href="https://www.greif-velox.com/kontakt/#c403">{{_rdosService._rdosConfig.contact}}</a></li>
    </ul>
</div>


<div class="stage stage-1">
    <div class="stage-product-image"></div>
    <div class="stage-content-wrapper margin-bottom">
        <div class="stage-headline">
            <span class="stage-headline-light">{{_rdosService._rdosConfig.headLine}}</span>
            <span class="stage-headline-dark"> {{_rdosService._rdosConfig.headLine2}}</span>
        </div>
        <div [ngClass]="language == 'en' ? 'stage-cert-en' : 'stage-cert' "></div>
        <div class="stage-subtitle" [innerHTML] ="_rdosService._rdosConfig.subLine"></div>

    </div>
</div>



<div class="container p-5" id="start">
    <div class="row justify-content-center">
        <span class="stage-headline sent" *ngIf="this._serverService.sentSuccess" [innerHTML] ="_rdosService._rdosConfig.mailSent"></span>
        <span class="stage-headline sent" *ngIf="!this._serverService.sentSuccess">Ihre Konfiguration für diesen Füllanlagentyp konnte nicht übermittelt werden.</span>
    </div>
</div>
<footer class="py-5"></footer>
