import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DosComponent } from './dos/dos.component';
import { RdosComponent } from './rdos/rdos.component';
import { RdosControlComponent } from './rdos-control/rdos-control.component';
import { LaterComponent } from './later/later.component';
import { SentComponent } from './sent/sent.component';


const routes: Routes = [
    { path: '', component: DosComponent },
    { path: 'rdos', component: RdosComponent },
    { path: 'rdos-control', component: RdosControlComponent },
    { path: 'later', component: LaterComponent },
    { path: 'sent', component: SentComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
