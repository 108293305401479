
export class RDos {
    id: number = 1;
    name: string = 'R-DOS';
    firstname: string = '';
    lastname: string = '';
    telephone: string = '';
    company: string = '';
    email: string = '';
    position: string = '';
    street: string = '';
    postal_code: string = '';
    city: string = '';
    country: string = '';
    form_of_address: string = '';
    privacy: boolean = false;
    newsletter: boolean = false;


    //All sites
    headLine: string = 'Greif Velox'
    headLine2: string = 'Anlagen Konfigurator'
    subLine: string = 'Der Greif-Velox Anlagen Konfigurator bietet Ihnen die Möglichkeit zur Konfiguration unserer innovativen Anlagen zur Abfüllung von Feststoffen und Flüssigkeiten. In wenigen Schritten definieren Sie Ihren Bedarf und gelangen zu Ihrem Angebot. <br> <br> Aktuell arbeiten wir daran Ihnen weitere Anlagen in unserem Konfigurator zur Verfügung zu stellen. Sollten Sie den von Ihnen gewünschten Anlagentypen nicht finden kontaktieren Sie uns gerne direkt.'
    ger: string = "DEU";
    en: string = "ENG"
    start: string = "STARTEN"
    pallets: string = "Paletten";
    canister: string = "Kanister";
    barrels: string = "Fässer";
    ibc: string = "IBC"
    contact: string = "Kontakt"
    inputPlaceholder: string = 'Eingabe..';

    //DOS
    dosHeadLine: string = "Wählen Sie Ihre"
    dosSubLine: string = "Paletten Füllanlage"
    dosCoose: string = "Anlage wählen"
    dosRDOS: string = "Radial Füllanlagen"
    dosKDOS: string = "Koordination Füllanlagen"
    dosADOS: string = "Automatische Füllanlagen"

    //RDOS-CONTROL
    basic_rdos: string = "Greif-Velox Radialfüllanlage R-DOS"
    basic_rdos_subline: string = "Ausführung in Edelstahl"
    radial: string = "Radial Füllanlagen"
    radial_subline: string = "Ausführung in Edelstahl"
    packages: string = "Wählen Sie Ihre Steuerung:"
    nonExEx: string = "Steuerung non-ATEX und ATEX"
    choose: string = "Auswahl treffen";
    ex: string = "Steuerung ATEX"
    nonEx: string = "Steuerung Non-ATEX"


    //RDOS
    additionalBtn: string = "Produktangaben (Optional)";
    offerTitle: string = "Angebots PDF";
    offerheadLine: string = "Erstellen Sie hier Ihr"
    isEx: boolean = true;
    smallHint: string = 'Durch klicken können Sie weitere Produkte hinzufügen.';
    neededElementsTitle: string = 'Paletten Füllanlage';
    neededElements: string[] = [
        'Fülleinheit BLU 1200',
        'Absaughaube mit Stutzen ø88,9 rechts',
        'BHV motorisch, 800mm',
        'Druckzylinder',
        'Schauscheibe aus leitf. Europlex',
        'Ventilhubeinheit BLU/1200/800',
        'Schauscheibe aus Plexiglas',
        'Wartungseinheit mit Öler (bei motorischer BHV)',
        'Wegmesseinheit',
        'P- Steuerung für BHV motorisch / halbautomatisch',
        'Wartungseinheit mit Öler',
        'Standsäule R-DOS für Durchfahrhöhe 1300 bei Gruben- oder Bodenwaage',
        'Ausleger R-DOS',
        'Steuerkastenhalter für Bedienkasten 300x400',
        'Griff',
        'P-Steuerung R-DOS-Bremse',
    ]

    neededElementsPrice: number = 21000.11;
    showNeededElementsPrice: boolean = false;
    neededElementsNumber: string = '16075357';
    showArtNr: false;

    defaultElementsTitle: string = 'Radial Füllanlagen';
    defaultElements: string[] = [

    ]

    defaultElementsPrice: number = 1000.11;
    showDefaultElementsPrice: boolean = false;
    defaultElementsNumber: string = '12345678';

    //Combine of needed and default
    combineElementsTitle: string = 'Basis Greif-Velox R-DOS';
    combineElementsPrice: number = 20.360 ;
    combineElementsNumber: string = '16075357';
    //SEND
    mailSent: string = 'Herzlichen Dank für Ihre Anfrage. Ihre Konfiguration wird von einem unserer Experten geprüft und Ihnen anschließend zur Verfügung gestellt. Kontaktieren Sie uns gerne per Telefon: <a href="tel:+4945153030" >+49 451 53 03 0</a>, E-Mail: <a href="mailto:info@greif-velox.com?">info@greif-velox.com</a>  oder über unseren Kontaktbereich.'

    // LATER
    konfiguration_soon: string ="Eine Konfiguration für diesen Füllanlagentyp wird in Kürze verfügbar sein."
    konfiguration_soon_subline: string ="Greif Velox Paletten Füllanlage."

    // POPUP
    popupHeader: string = "Angebotsrelevante Informationen"
    emptyRequiredFields: string = "Bitte füllen Sie alle mit '*' markierten Felder aus und akzeptieren Sie die Datenschutzbestimmungen."
    
    enterCompany: string = "Firma"
    enterPosition: string = "Position"
    enterTitle: string = "Anrede"
    enterFirstName: string = "Vorname"
    enterLastName: string = "Nachname"
    enterPhoneNumber: string = "Telefonnummer"
    enterStreet: string = "Straße"
    enterPostalCode: string = "PLZ"
    enterCity: string = "Stadt"
    enterCountry: string = "Land"
    enterEmail: string = "Email-Adresse"
    checkPolicy1: string = "Sie erklären sich damit einverstanden, dass Ihre Daten zur Bearbeitung Ihres Anliegens verwendet werden dürfen. Weitere Informationen und Widerrufshinweise finden Sie in der "
    checkPolicy2: string = "Datenschutzerklärung"
    checkNewsletter: string = "Ich möchte in den Greif Velox Newsletter aufgenommen werden."
    send: string = "Absenden"
    
    selectMale: string = "Herr"
    selectWoman: string = "Frau"

    //selectionType: 'eitherOr' , 'count', 'if', 'optional'

    rdosHeadLine: string = "Bitte konfigurieren Sie Ihre Greif-Velox R-Dos in"
    rdosSubLine1: string = "ATEX Ausführung"
    rdosSubLine2: string = "non ATEX Ausführung"
    rdosHaveToSelect: string = "Diese Komponenten müssen gewählt werden"
    rdosFooter: string = "Sonderfarben werden auf Anfrage kalkuliert."

    enterNumber: string = "Anzahl - eingeben"

    equipmentCategories: {}[] = [

        {//0
            selectionType: 'automatic',
            catName: '',
            catSubname: 'Abhängig von Paketauswahl Steuerung:',
            //catTooltip: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras sit amet risus bibendum, lobortis nulla eu, finibus sapien. Sed eu nibh quam. Sed quis viverra risus. Integer fermentum a dui id varius."*/,
            catSubnameAddon: '',
            catSubnameStar: true,
            catNumber: '3',
            showArtNr: false,
            equipment: [
                {
                    equipmentName: 'Transsonarer-Wegaufnehmer',
                    countableIsRelatedToEquipmentCategoriesIndex: null,
                    countableIsRelatedToEquipmentIndex: null,
                    price: 2370.00,
                    artNr: '05070409',
                    showArtNr: false,
                    enabled: true,
                    checked: false,
                },
                {
                    equipmentName: 'Ex: inkl. Stromlaufplänen und Schaltschrank ',
                    countableIsRelatedToEquipmentCategoriesIndex: null,
                    countableIsRelatedToEquipmentIndex: null,
                    price: 33850.00,
                    artNr: '16075703',
                    showArtNr: false,
                    enabled: true,
                    checked: false,
                },
                {
                    equipmentName: 'Wegmesssensor Hub 1200,ex-frei',
                    countableIsRelatedToEquipmentCategoriesIndex: null,
                    countableIsRelatedToEquipmentIndex: null,
                    price: 1320,
                    artNr: '16075244',
                    showArtNr: false,
                    enabled: true,
                    checked: false,
                },
                {
                    equipmentName: 'Non-Ex: inkl. Stromlaufplänen und Schaltschrank',
                    countableIsRelatedToEquipmentCategoriesIndex: null,
                    countableIsRelatedToEquipmentIndex: null,
                    price: 15385.00,
                    artNr: '16075702',
                    showArtNr: false,
                    enabled: true,
                    checked: true,
                }
            ]
        },
        {//2
            selectionType: 'count',
            catName: '',
            catSubname: 'Bestimmen Sie die Anzahl der Dosierventile:',
            //catTooltip: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras sit amet risus bibendum, lobortis nulla eu, finibus sapien. Sed eu nibh quam. Sed quis viverra risus. Integer fermentum a dui id varius.",
            catSubnameAddon: '',
            catSubnameStar: true,
            catNumber: '4',
            showArtNr: false,
            equipment: [
                {
                    equipmentName: 'Dosierventil BLV25/1200, Viton-O-Ringe',
                    equipmentToolTip: 'Füllrohr-ø33,7; ohne Staurohr; mit Flansch DN50. O-Ringe aus Kalrez und FEP-ummanteltem Viton, geeignet für Lebensmittel.',
                    countableIsRelatedToEquipmentCategoriesIndex: null,
                    countableIsRelatedToEquipmentIndex: null,
                    price: 1250,
                    artNr: '16075382',
                    showArtNr: false,
                    countable: true,
                    showPlaceholder: true,
                    count: null,
                    checked: false,
                    textfield: false,
                    enabled: true
                },
                {
                    equipmentName: 'Dosierventil BLV32/1200, Kalrez-O-Ringe',
                    equipmentToolTip: 'Füllrohr-ø42,4; ohne Staurohr; mit Flansch DN50. O-Ringe aus Kalrez und FEP-ummanteltem Viton, geeignet für Lebensmittel.',
                    countableIsRelatedToEquipmentCategoriesIndex: null,
                    countableIsRelatedToEquipmentIndex: null,
                    price: 1780,
                    artNr: '16075336',
                    showArtNr: false,
                    countable: true,
                    showPlaceholder: true,
                    count: null,
                    checked: false,
                    textfield: false,
                    enabled: true
                },
                {
                    equipmentName: 'Dosierventil BLV50/1200, Viton-O-Ringe',
                    equipmentToolTip: 'Füllrohr-ø60,3; ohne Staurohr; mit Flansch DN50. O-Ringe aus Kalrez und FEP-ummanteltem Viton, geeignet für Lebensmittel',
                    countableIsRelatedToEquipmentCategoriesIndex: 4,
                    countableIsRelatedToEquipmentIndex: 1,
                    price: 2655,
                    artNr: '16075386',
                    showArtNr: false,
                    countable: true,
                    showPlaceholder: true,
                    count: null,
                    checked: false,
                    textfield: false,
                    enabled: true
                }
            ]
        },
        {//3
            selectionType: 'count',
            catName: '',
            catSubname: 'Bestimmen Sie die Anzahl der Abstreifringe:',
            //catTooltip: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras sit amet risus bibendum, lobortis nulla eu, finibus sapien. Sed eu nibh quam. Sed quis viverra risus. Integer fermentum a dui id varius.",
            catSubnameAddon: '',
            catSubnameStar: true,
            catNumber: '5',
            showArtNr: false,
            equipment: [
                {
                    equipmentName: 'Abstreifring DN 25',
                    equipmentToolTip: 'aus PTFE mit O-Ring aus FEP-ummanteltem Viton.',
                    countableIsRelatedToEquipmentCategoriesIndex: null,
                    countableIsRelatedToEquipmentIndex: null,
                    price: 100.00,
                    artNr: '16075246',
                    showArtNr: false,
                    countable: true,
                    showPlaceholder: true,
                    count: null,
                    checked: false,
                    textfield: false,
                    enabled: true
                },
                {
                    equipmentName: 'Abstreifring DN 32',
                    equipmentToolTip: 'aus PTFE mit O-Ring aus FEP-ummanteltem Viton.',
                    countableIsRelatedToEquipmentCategoriesIndex: 4,
                    countableIsRelatedToEquipmentIndex: 1,
                    price: 100.00,
                    artNr: '16033310',
                    showArtNr: false,
                    countable: true,
                    showPlaceholder: true,
                    count: null,
                    checked: false,
                    textfield: false,
                    enabled: true
                },
                {
                    equipmentName: 'Abstreifring DN 50',
                    equipmentToolTip: 'aus PTFE mit O-Ring aus FEP-ummanteltem Viton.',
                    countableIsRelatedToEquipmentCategoriesIndex: 4,
                    countableIsRelatedToEquipmentIndex: 0,
                    price: 180.00,
                    artNr: '16075290',
                    showArtNr: false,
                    countable: true,
                    showPlaceholder: true,
                    count: null,
                    checked: false,
                    enabled: true
                }
            ]
        },
        {//4
            selectionType: 'eitherOr',
            catName: '',
            catSubname: 'Wählen Sie einen Tropfenfänger:',
            // catSubnameAddon: '(automatisch ausgewählt)',
            //catTooltip: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras sit amet risus bibendum, lobortis nulla eu, finibus sapien. Sed eu nibh quam. Sed quis viverra risus. Integer fermentum a dui id varius.",
            catSubnameStar: false,
            catNumber: '6',
            showArtNr: false,
            selectionSubType: 'if',
            // catSubSubname: 'Wählen Sie einen Pneu. Tropfenfänger:',
            catSubSubnameAddon: '',
            catSubSubnameStar: true,
            equipment: [
                {//0
                    equipmentName: 'mechan. Tropfenfänger',
                    countableIsRelatedToEquipmentCategoriesIndex: null,
                    countableIsRelatedToEquipmentIndex: null,
                    price: 420.00,
                    artNr: '16068952',
                    showArtNr: false,
                    checked: false,
                    textfield: false,
                    enabled: true
                },
                {//1
                    equipmentName: 'Pneumatischer Tropfenfänger mit PTFE-Schale',
                    countableIsRelatedToEquipmentCategoriesIndex: null,
                    countableIsRelatedToEquipmentIndex: null,
                    price: 435.00,
                    artNr: '16075339',
                    showArtNr: false,
                    checked: false,
                    textfield: false,
                    enabled: true
                },
                {//2
                    equipmentName: 'Pneumatischer Tropfenfänger mit Einweg-Alu-Schale',
                    countableIsRelatedToEquipmentCategoriesIndex: null,
                    countableIsRelatedToEquipmentIndex: null,
                    price: 410.00,
                    artNr: '16075338',
                    showArtNr: false,
                    checked: false,
                    textfield: false,
                    enabled: true
                }
            ]
        },
        {//5
            selectionType: 'automatic',
            catName: '',
            catSubname: 'Manuelle Erdungszangen: ',
            //catTooltip: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras sit amet risus bibendum, lobortis nulla eu, finibus sapien. Sed eu nibh quam. Sed quis viverra risus. Integer fermentum a dui id varius.",
            catSubnameAddon: '(automatisch ausgewählt)',
            catSubnameStar: false,
            catNumber: '7',
            showArtNr: false,
            equipment: [
                {
                    equipmentName: 'Erdungszange mit Auswertegerät',
                    countableIsRelatedToEquipmentCategoriesIndex: null,
                    countableIsRelatedToEquipmentIndex: null,
                    price: 2250.00,
                    artNr: '16075356',
                    showArtNr: false,
                    checked: false,
                    textfield: false,
                    enabled: false
                }
            ]
        },
    ]

    optionalEquipmentCategories: {}[] = [
        {//0
            selectionType: 'eitherOr',
            catName: 'Optionales Zubehör',
            catSubname: 'Wählen Sie eine Waage:',
            //catTooltip: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras sit amet risus bibendum, lobortis nulla eu, finibus sapien. Sed eu nibh quam. Sed quis viverra risus. Integer fermentum a dui id varius.",
            catSubnameAddon: '(optional)',
            catSubnameStar: false,
            catNumber: '8',
            showArtNr: false,
            selectionSubType: 'optional',
            catSubSubnameAddon: '',
            catSubSubnameStar: false,
            equipment: [
                {//0
                    equipmentName: 'Sockel R-DOS [Anbindung an Fördertechnik]',
                    equipmentToolTip: 'zur Erhöhung der Standsäule um 500mm. Inklusive Bedienbühne zwischen Fördertechnik und Bedienschrank an der Standsäule.',
                    countableIsRelatedToEquipmentCategoriesIndex: null,
                    countableIsRelatedToEquipmentIndex: null,
                    price: 1105.00,
                    artNr: '16075305',
                    showArtNr: false,
                    checked: false,
                    textfield: false,
                    enabled: true
                },
                {//1
                    equipmentName: 'Grubenwaage 1500x1250',
                    equipmentToolTip: 'Lastaufnehmer 1500x1250, aus Edelstahl, inklusive Grubenrahmen aus Edelstahl, eichfähig, 2 Teilungswaage, Tragkraft: 4000 kg, Wägebereich: 600/ 1500 kg, Ziffernschrit: 200 / 500 g, Wägezellen: Edelstahl, IP68/69k (SB4-10kN-C3 MI7,5), Wägezellenkabel: 6m PUR, Kabelkasten: extern - Flintec KPK-4 aus Polyester,  geeignet für Ex- und ex-freie Umgebungen.',
                    countableIsRelatedToEquipmentCategoriesIndex: null,
                    countableIsRelatedToEquipmentIndex: null,
                    price: 6100.00,
                    artNr: '05320335',
                    showArtNr: false,
                    checked: false,
                    textfield: false,
                    enabled: true
                },
                {//2
                    equipmentName: 'Bodenwaage 1500x1250',
                    equipmentToolTip: 'Lastaufnehmer 1500x1250, aus Edelstahl, inklusive Rammschutz 3-seitig aus Normalstahl, Ex-Zone 1, II 2G ib T4, inkl. Verschiebesicherung, eichfähig, 2 Teilungswaage, Tragkraft: 3500 kg, Wägebereich: 600/ 1500 kg, Ziffernschrit: 200 / 500 g, Wägezellen: Edelstahl, IP68/69k (SB4-10kN-C3 MI7,5-Ex), Wägezellenkabel: 6m PUR,    Kabelkasten: extern, Flintec KPK-4 aus Polyester, geeignet für Ex- und ex-freie Umgebungen.',
                    countableIsRelatedToEquipmentCategoriesIndex: null,
                    countableIsRelatedToEquipmentIndex: null,
                    price: 6520.00,
                    artNr: '16057922',
                    showArtNr: false,
                    checked: false,
                    textfield: false,
                    enabled: true
                },
                {//3
                    equipmentName: 'U-Waage 1250x1450',
                    equipmentToolTip: 'U-Waage 1250x1450x80, aus Edelstahl, inkl. Rammschutz von vorn aus 1.4301, eichfähig als SWA, für ATEX Ex 2G ib,  2-Teilung 2x3000d, Wägebereich nach SWA: 20 – 600 / 1.500 kg, .',
                    countableIsRelatedToEquipmentCategoriesIndex: null,
                    countableIsRelatedToEquipmentIndex: null,
                    price: 9880.00,
                    artNr: '16075376',
                    showArtNr: false,
                    checked: false,
                    textfield: false,
                    enabled: true
                }
            ],
            subEquipment: [
                {//4
                    equipmentName: 'Laserpointer Ex',
                    equipmentToolTip: 'als Zielhilfe statt Kettchen, erzeugt einen grünen Punkt vor dem Spundloch. geeignet für Ex- und ex-freie Umgebungen.',
                    countableIsRelatedToEquipmentCategoriesIndex: null,
                    countableIsRelatedToEquipmentIndex: null,
                    price: 2250.00,
                    artNr: '16075389',
                    showArtNr: false,
                    checked: false,
                    textfield: false,
                    enabled: true
                },
                {//5
                    equipmentName: 'Ausleger mit Schrauber',
                    equipmentToolTip: 'schwenkbarer Ausleger an der Standsäule, inklusive ATEX-Schrauber, auch für ex-freie Umgebungen, geeignet für 2" Tri-Sure-Stopfen aus Stahl ',
                    countableIsRelatedToEquipmentCategoriesIndex: null,
                    countableIsRelatedToEquipmentIndex: null,
                    price: 7145.00,
                    artNr: '16075365',
                    showArtNr: false,
                    checked: false,
                    textfield: false,
                    enabled: true
                },
                {//6
                    equipmentName: 'Clincher',
                    equipmentToolTip: ' zum Anhängen an den Ausleger mit Schrauber, geeignet für Tri-Sure Tabseal-Kappen.',
                    countableIsRelatedToEquipmentCategoriesIndex: null,
                    countableIsRelatedToEquipmentIndex: null,
                    price: 2995.00,
                    artNr: '16075366',
                    showArtNr: false,
                    checked: false,
                    textfield: false,
                    enabled: true
                },
                {//7
                    equipmentName: 'Ventilparkständer für 2 Ventile',
                    countableIsRelatedToEquipmentCategoriesIndex: null,
                    countableIsRelatedToEquipmentIndex: null,
                    price: 1590.00,
                    artNr: 'NN',
                    showArtNr: false,
                    checked: false,
                    enabled: true,
                    combofield: true,
                    comboname: "Eingabe",
                    combovalue: "",
                },
                {//8
                    equipmentName: 'Produktzuführung',
                    equipmentToolTip: 'bestehend aus: Halter für Kugelhahn an der Standsäule, Vorsteuerkugelhahn aus Edelstahl, Drehgelenk aus Edelstahl,  Produktschlauch Fluorflex aus PTFE, L=3500, Produktschlauchabhängung mit Federzug',
                    countableIsRelatedToEquipmentCategoriesIndex: null,
                    countableIsRelatedToEquipmentIndex: null,
                    price: 5200.00,
                    artNr: '16075398',
                    showArtNr: false,
                    checked: false,
                    textfield: false,
                    enabled: true
                },
            ],
        }
    ]

    additionalEquipmentCategories:{}[] = [

        {//0
            selectionType: 'automatic',
            catName: 'Optionales Zubehör',
            catSubname: 'Angaben zu Produkten:',
            //catTooltip: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras sit amet risus bibendum, lobortis nulla eu, finibus sapien. Sed eu nibh quam. Sed quis viverra risus. Integer fermentum a dui id varius.",
            catSubnameAddon: '(optional)',
            catSubnameStar: false,
            catNumber: '9',
            showArtNr: false,
            selectionSubType: 'optional',
            addType: 'product',
            catSubSubnameAddon: '',
            catSubSubnameStar: false,
            equipment: [
                {
                    equipmentName: 'Produktname ',
                    countableIsRelatedToEquipmentCategoriesIndex: null,
                    countableIsRelatedToEquipmentIndex: null,
                    price: "",
                    artNr: '',
                    showArtNr: false,
                    enabled: true,
                    checked: false,
                    textfield: true,
                    text: "",
                },
                {
                    equipmentName: 'Dichte (kg/dm³)  ',
                    countableIsRelatedToEquipmentCategoriesIndex: null,
                    countableIsRelatedToEquipmentIndex: null,
                    price: "",
                    artNr: '',
                    showArtNr: false,
                    enabled: true,
                    checked: false,
                    textfield: true,
                    text: "",
                },
                {
                    equipmentName: 'Temperatur (°C)',
                    countableIsRelatedToEquipmentCategoriesIndex: null,
                    countableIsRelatedToEquipmentIndex: null,
                    price: "",
                    artNr: '',
                    showArtNr: false,
                    enabled: true,
                    checked: false,
                    textfield: true,
                    text: "",
                },
                {
                    equipmentName: 'Schaumbildung?',
                    countableIsRelatedToEquipmentCategoriesIndex: null,
                    countableIsRelatedToEquipmentIndex: null,
                    price: "",
                    artNr: '',
                    showArtNr: false,
                    enabled: true,
                    checked: false,
                },
                {
                    equipmentName: 'Toxisch?',
                    countableIsRelatedToEquipmentCategoriesIndex: null,
                    countableIsRelatedToEquipmentIndex: null,
                    price: "",
                    artNr: '',
                    showArtNr: false,
                    enabled: true,
                    checked: false,
                },
                {
                    equipmentName: 'Brennbar?',
                    countableIsRelatedToEquipmentCategoriesIndex: null,
                    countableIsRelatedToEquipmentIndex: null,
                    price: "",
                    artNr: '',
                    showArtNr: false,
                    enabled: true,
                    checked: false,
                    combofield: true,
                    comboname: "Flammpunkt in °C ",
                    combovalue: "",

                },
                {
                    equipmentName: 'Ätzend?',
                    countableIsRelatedToEquipmentCategoriesIndex: null,
                    countableIsRelatedToEquipmentIndex: null,
                    price: "",
                    artNr: '',
                    showArtNr: false,
                    enabled: true,
                    checked: false,
                    combofield: true,
                    comboname: "pH-wert",
                    combovalue: "",
                },
                {
                    equipmentName: 'Gasend?',
                    countableIsRelatedToEquipmentCategoriesIndex: null,
                    countableIsRelatedToEquipmentIndex: null,
                    price: "",
                    artNr: '',
                    showArtNr: false,
                    enabled: true,
                    checked: false,
                },
                {
                    equipmentName: 'Aushärtend?',
                    countableIsRelatedToEquipmentCategoriesIndex: null,
                    countableIsRelatedToEquipmentIndex: null,
                    price: "",
                    artNr: '',
                    showArtNr: false,
                    enabled: true,
                    checked: false,
                    combofield: true,
                    comboname: "Erstarrungspunkt in °C",
                    combovalue: "",

                },
                {
                    equipmentName: 'Klebrig?',
                    countableIsRelatedToEquipmentCategoriesIndex: null,
                    countableIsRelatedToEquipmentIndex: null,
                    price: "",
                    artNr: '',
                    showArtNr: false,
                    enabled: true,
                    checked: false,
                },
                {
                    equipmentName: 'Viskosität(mPas) ',
                    countableIsRelatedToEquipmentCategoriesIndex: null,
                    countableIsRelatedToEquipmentIndex: null,
                    price: "",
                    artNr: '',
                    showArtNr: false,
                    enabled: true,
                    textfield: true,
                    text: "",
                    checked: false,
                },
            ]
        },
        {//1
            selectionType: '',
            catName: 'Optionales Zubehör',
            catSubname: 'Angaben zu Gebinden:',
            //catTooltip: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras sit amet risus bibendum, lobortis nulla eu, finibus sapien. Sed eu nibh quam. Sed quis viverra risus. Integer fermentum a dui id varius.",
            catSubnameAddon: '(optional)',
            catSubnameStar: false,
            catNumber: '10',
            showArtNr: false,
            selectionSubType: 'optional',
            addType: 'container',
            catSubSubnameAddon: '',
            catSubSubnameStar: false,
            equipment: [
                {
                    equipmentName: 'Gebindename ',
                    countableIsRelatedToEquipmentCategoriesIndex: null,
                    countableIsRelatedToEquipmentIndex: null,
                    price: "",
                    artNr: '',
                    showArtNr: false,
                    enabled: true,
                    checked: false,
                    textfield: true,
                    text: "",
                },
                {
                    equipmentName: 'Behälterinhalt (l)',
                    equipmentToolTip: '',
                    countableIsRelatedToEquipmentCategoriesIndex: null,
                    countableIsRelatedToEquipmentIndex: null,
                    price: "",
                    artNr: '',
                    showArtNr: false,
                    count: null,
                    checked: false,
                    textfield: true,
                    text: "",
                    enabled: true
                },
                {
                    equipmentName: 'Füllgewicht Netto (kg)',
                    equipmentToolTip: '',
                    countableIsRelatedToEquipmentCategoriesIndex: null,
                    countableIsRelatedToEquipmentIndex: null,
                    price: "",
                    artNr: '',
                    showArtNr: false,
                    count: null,
                    checked: false,
                    textfield: true,
                    text: "",
                    enabled: true
                },
            ]
        },
        {//2
            selectionType: 'eitherOr',
            catName: 'Optionales Zubehör',
            catSubname: 'Angaben zu Paletten:',
            //catTooltip: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras sit amet risus bibendum, lobortis nulla eu, finibus sapien. Sed eu nibh quam. Sed quis viverra risus. Integer fermentum a dui id varius.",
            catSubnameAddon: '(optional)',
            catSubnameStar: false,
            catNumber: '11',
            showArtNr: false,
            selectionSubType: 'optional',
            addType: 'pallet',
            catSubSubnameAddon: '',
            catSubSubnameStar: false,
            equipment: [
                {
                    equipmentName: 'Typ',
                    equipmentToolTip: '.',
                    countableIsRelatedToEquipmentCategoriesIndex: null,
                    countableIsRelatedToEquipmentIndex: null,
                    price: "",
                    artNr: '',
                    showArtNr: false,
                    checked: false,
                    textfield: true,
                    text: "",
                    enabled: true
                },
                {
                    equipmentName: 'Länge (mm)',
                    equipmentToolTip: 'aus PTFE mit O-Ring aus FEP-ummanteltem Viton.',
                    countableIsRelatedToEquipmentCategoriesIndex: 4,
                    countableIsRelatedToEquipmentIndex: 1,
                    price: "",
                    artNr: '',
                    showArtNr: false,
                    checked: false,
                    textfield: true,
                    text: "",
                    enabled: true
                },
                {
                    equipmentName: 'Breite (mm)',
                    equipmentToolTip: 'aus PTFE mit O-Ring aus FEP-ummanteltem Viton.',
                    countableIsRelatedToEquipmentCategoriesIndex: 4,
                    countableIsRelatedToEquipmentIndex: 0,
                    price: "",
                    artNr: '',
                    showArtNr: false,
                    checked: false,
                    textfield: true,
                    text: "",
                    enabled: true
                },
                {
                    equipmentName: 'Höhe (mm)',
                    equipmentToolTip: 'aus PTFE mit O-Ring aus FEP-ummanteltem Viton.',
                    countableIsRelatedToEquipmentCategoriesIndex: 4,
                    countableIsRelatedToEquipmentIndex: 1,
                    price: "",
                    artNr: '',
                    showArtNr: false,
                    checked: false,
                    textfield: true,
                    text: "",
                    enabled: true
                },
                {
                    equipmentName: 'Gewicht (kg)',
                    equipmentToolTip: 'aus PTFE mit O-Ring aus FEP-ummanteltem Viton.',
                    countableIsRelatedToEquipmentCategoriesIndex: 4,
                    countableIsRelatedToEquipmentIndex: 1,
                    price: "",
                    artNr: '',
                    showArtNr: false,
                    checked: false,
                    textfield: true,
                    enabled: true,
                    text: "",
                },
            ]
        }
    ]
}