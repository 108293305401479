import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy, Input, ViewChild } from '@angular/core';
import { RdosService } from '../services/rdos.service';
import { trigger, transition, style, animate, state, AnimationEvent } from '@angular/animations';
import { ServerService } from '../services/server.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import {ViewportScroller} from '@angular/common';
export type FadeState = 'visible' | 'hidden';

@Component({
  selector: 'app-rdos',
  templateUrl: './rdos.component.html',
  styleUrls: ['./rdos.component.scss'],
  animations: [
    trigger('state', [
      state(
        'visible',
        style({
          opacity: '1'
        })
      ),
      state(
        'hidden',
        style({
          opacity: '0'
        })
      ),
      transition('* => visible', [animate('500ms ease-out')]),
      transition('visible => hidden', [animate('500ms ease-out')])
    ]),

    // trigger name for attaching this animation to an element using the [@triggerName] syntax
    trigger('fadeAnimation', [

      // route 'enter' transition
      transition(':enter', [

        // css styles at start of transition
        style({ opacity: 0 }),

        // animation and styles at end of transition
        animate('1.3s', style({ opacity: 1 }))
      ]),
      // route 'enter' transition
      transition(':leave', [

        // css styles at start of transition
        style({ opacity: 1 }),

        // animation and styles at end of transition
        animate('1.3s', style({ opacity: 0 }))
      ]),
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class RdosComponent implements OnInit {
  additionalOptions: boolean = false;
    fragment;

  constructor(public _rdosService: RdosService, private _serverService: ServerService, private cdRef: ChangeDetectorRef, private modalService: NgbModal, private activatedRoute: ActivatedRoute, protected readonly router: Router, private viewportScroller: ViewportScroller
) {
      this.fragment = this.activatedRoute.snapshot.fragment
   }
  showOtherFlag: boolean;

  theCheckbox = false;
  marked = false;
  showDownloadBtn = false;
  closeResult = '';
  language;
  state: FadeState;
  private _show: boolean;
  get show() {
    return this._show;
  }

  @Input()
  set show(value: boolean) {
    if (value) {
      // show the content and set it's state to trigger fade in animation
      this._show = value;
      this.state = 'visible';
    } else {
      // just trigger the fade out animation
      this.state = 'hidden';
    }
  }

  animationDone(event: AnimationEvent) {
    // now remove the
    if (event.fromState === 'visible' && event.toState === 'hidden') {
      this._show = false;
    }
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params['language'] != null && params['language'] != undefined) {
        this.language = params['language']
        this.showOtherFlag = false;
        this._rdosService.setLanguage(this.language);
        this.language = this._rdosService.currentLanguage;
      }
      this.language = this._rdosService.currentLanguage;

    });
    document.addEventListener("scroll", e => {
      let scrollTop = window.pageYOffset && document.documentElement.scrollTop;
      //console.log(scrollTop);
      this.showDownloadBtn = (scrollTop > 500);
      this.show = (scrollTop > 500);
    });


  }

    scroll(id){
        const elmnt = document.getElementById(id);
        const height = elmnt.getBoundingClientRect().height; //wrong value - gets 1735

        var topOfElement = height + 130;
        setTimeout(() => window.scroll({ top: topOfElement, behavior: "smooth" }), 100);
    }

    ngAfterViewInit(): void {
        if(!!this.fragment){
            this.scroll(this.fragment)
        }

        if (this._rdosService.getIsEx()) {

            this._rdosService._rdosConfig.equipmentCategories[0].equipment[0].enabled = false;
            this._rdosService._rdosConfig.equipmentCategories[0].equipment[0].checked = false;

            this._rdosService._rdosConfig.equipmentCategories[0].equipment[1].enabled = false;
            this._rdosService._rdosConfig.equipmentCategories[0].equipment[1].checked = false;

            this._rdosService._rdosConfig.equipmentCategories[0].equipment[2].enabled = false;
            this._rdosService._rdosConfig.equipmentCategories[0].equipment[2].checked = true;

            this._rdosService._rdosConfig.equipmentCategories[0].equipment[3].enabled = false;
            this._rdosService._rdosConfig.equipmentCategories[0].equipment[3].checked = true;

            this._rdosService._rdosConfig.equipmentCategories[4].equipment[0].checked = false;
            this._rdosService._rdosConfig.equipmentCategories[4].equipment[0].checked = false;
            this._rdosService._rdosConfig.equipmentCategories[4].equipment[0].enabled = true;

        } else {
            this._rdosService._rdosConfig.equipmentCategories[0].equipment[0].enabled = false;
            this._rdosService._rdosConfig.equipmentCategories[0].equipment[0].checked = true;

            this._rdosService._rdosConfig.equipmentCategories[0].equipment[1].enabled = false;
            this._rdosService._rdosConfig.equipmentCategories[0].equipment[1].checked = true;

            this._rdosService._rdosConfig.equipmentCategories[0].equipment[2].enabled = false;
            this._rdosService._rdosConfig.equipmentCategories[0].equipment[2].checked = false;

            this._rdosService._rdosConfig.equipmentCategories[0].equipment[3].enabled = false;
            this._rdosService._rdosConfig.equipmentCategories[0].equipment[3].checked = false;

            this._rdosService._rdosConfig.equipmentCategories[4].equipment[0].checked = true;
            this._rdosService._rdosConfig.equipmentCategories[4].equipment[0].enabled = false;

        }
  }

  ngAfterViewChecked() {


    if (this._rdosService.getIsEx()) {
      this._rdosService._rdosConfig.equipmentCategories[0].equipment[0].enabled = false;
      this._rdosService._rdosConfig.equipmentCategories[0].equipment[1].enabled = false;
      this._rdosService._rdosConfig.equipmentCategories[0].equipment[2].enabled = false;
      this._rdosService._rdosConfig.equipmentCategories[0].equipment[3].enabled = false;


    } else {
      this._rdosService._rdosConfig.equipmentCategories[0].equipment[0].enabled = false;
      this._rdosService._rdosConfig.equipmentCategories[0].equipment[1].enabled = false;
      this._rdosService._rdosConfig.equipmentCategories[0].equipment[2].enabled = false;
      this._rdosService._rdosConfig.equipmentCategories[0].equipment[3].enabled = false;


    }

    //remove the combovalue at unchecked combofield (additionalEquipmentCategories)
    this._rdosService._rdosConfig.additionalEquipmentCategories.forEach(additionalEquipmentCategory => {
        additionalEquipmentCategory.equipment.forEach(equipment => {
            if(undefined != equipment.combofield && equipment.combofield === true && equipment.checked === false){
                equipment.combovalue = '';
            }
        });
    });

    //remove the combovalue at unchecked combofield (optionalEquipmentCategories)
    this._rdosService._rdosConfig.optionalEquipmentCategories.forEach(optionalEquipmentCategory => {
        optionalEquipmentCategory.subEquipment.forEach(equipment => {
            if(undefined != equipment.combofield && equipment.combofield === true && equipment.checked === false){
                equipment.combovalue = '';
            }
        });
    });


    this.cdRef.detectChanges();
  }

  toggleChecked(e) {
    console.log(e);
  }
  modelChanged(equipmentItem) {
    console.log(equipmentItem);

    if (equipmentItem.countableIsRelatedToEquipmentCategoriesIndex !== null && equipmentItem.countableIsRelatedToEquipmentIndex !== null) {
      this._rdosService._rdosConfig.equipmentCategories[equipmentItem.countableIsRelatedToEquipmentCategoriesIndex].equipment[equipmentItem.countableIsRelatedToEquipmentIndex].checked = true;
    }
  }

  detectChange() {
    this.cdRef.detectChanges();
    console.log(this._rdosService._rdosConfig);
  }

  blendPlaceholder(equipmentItem, input) {
    equipmentItem.showPlaceholder = false;
    input.setFocus();
  }

  scrollToElement($element): void {
    console.log($element);
    $element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }

  /*buildPDF() {
    console.log(JSON.parse(JSON.stringify(this._rdosService._rdosConfig)));
    this._serverService.sendDataToServer();
  }*/
  test(e) {
    console.log(this.language);
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {

      //save btn clicked
      if ("true" == "true") {
        return
      }
      this._serverService.sendDataToServer(this.language);
      //console.log(this._rdosService._rdosConfig);

    }, (reason) => {
      //do nothing
      //console.log(reason);
    });
  }
  checkData() {
    console.log(this._rdosService._rdosConfig.privacy)
    if (this._rdosService._rdosConfig.firstname != "" && this._rdosService._rdosConfig.lastname != "" && this._rdosService._rdosConfig.company != ""
      && this._rdosService._rdosConfig.email != "" && this._rdosService._rdosConfig.position != "" && this._rdosService._rdosConfig.telephone != "" &&
      this._rdosService._rdosConfig.form_of_address != "Anrede" && this._rdosService._rdosConfig.privacy == true) {
      this._rdosService._rdosConfig.language = this._rdosService.currentLanguage;
      this._serverService.sendDataToServer(this.language);
      if(this._rdosService._rdosConfig.newsletter == true)
      this._serverService.mailChimp(this._rdosService._rdosConfig.company,this._rdosService._rdosConfig.position,this._rdosService._rdosConfig.firstname,this._rdosService._rdosConfig.lastname,this._rdosService._rdosConfig.email);
      this.modalService.dismissAll()
    } else {
      alert(this._rdosService._rdosConfig.emptyRequiredFields)
    }
    //this.modalService.dismissAll()
  }
  toggle(checkbox,item?) {
    console.log(item);
    if(item) {
      item.equipment.forEach(item => {
        item.checked = false;
      })
    }
    if (checkbox.true == true) {
      checkbox.checked = false;
    }
  }
  changeLanguage(event) {
    this.language = event
    this.showOtherFlag = false;
    this.router.navigate([], { queryParams: { language: event } })

    this._rdosService.setLanguage(this.language);
  }
  openLanguages() {
    this.showOtherFlag = true;
  }
  closeLanguages() {
    this.showOtherFlag = false;
  }
  moreOptions() {
    this.additionalOptions = !this.additionalOptions;
  }
  anotherContent(anotherContent,index) {
    let copy = JSON.parse(JSON.stringify(anotherContent))
    copy.equipment.forEach(equipment => {
      if(undefined != equipment.checked){
        equipment.checked = false
      }
      if(undefined != equipment.text){
        equipment.text = "";
      }
    });
    this._rdosService._rdosConfig.additionalEquipmentCategories.splice(index+1,0,copy)
  }
}
