import { Component, OnInit } from '@angular/core';
import { RdosService } from '../services/rdos.service';
import { ServerService } from '../services/server.service';

@Component({
  selector: 'app-sent',
  templateUrl: './sent.component.html',
  styleUrls: ['./sent.component.scss']
})
export class SentComponent implements OnInit {
    language;
    showOtherFlag: boolean;

  constructor(public _serverService: ServerService,public _rdosService: RdosService) { }

  ngOnInit() {
    this.language = this._rdosService.currentLanguage;
  }
  ngAfterViewInit(){
    this.scroll("start")
  }
  changeLanguage(event){
    this.language = event
    this.showOtherFlag = false;
    this._rdosService.setLanguage(this.language);
 }
 openLanguages(){
     this.showOtherFlag = true;
 }
 closeLanguages() {
    this.showOtherFlag = false;
}
scroll(id){
  const elmnt = document.getElementById(id);
  const height = elmnt.getBoundingClientRect().height; //wrong value - gets 1735

  var topOfElement = height + 500;
  setTimeout(() => window.scroll({ top: topOfElement, behavior: "smooth" }), 100);
}
}
