import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { RdosService } from './rdos.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ServerService {

  private _serviceURL = 'https://konfigurator.greif-velox.com/server/server.php';
  // private _serviceURL = 'https://konfigurator-dev.greif-velox.com/server/server.php';

  private httpOptions;
  sentSuccess = false;

  constructor(private http: HttpClient, private _rdosService: RdosService, private router: Router) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/x-www-form-urlencoded'
      }),
      responseType: 'json'
    };
  }

  sendDataToServer(language){

    this.sendPostRequest(this._rdosService._rdosConfig).subscribe(
      res => {
        //console.log(res);
        if(res==='true'){
          this.sentSuccess = true;
        }else{
          this.sentSuccess = true;
        }
        if (this._rdosService.currentLanguage == 'de') {
          this.router.navigate(['/sent'], { queryParams: { language: "de" } })
        } else {
          this.router.navigate(['/sent'], { queryParams: { language: "en" } })
        }
      }
    );
  }
  async mailChimp(company,position,forname,lastname,email){
    let formdataProject = new FormData();
    formdataProject.append('MMERGE1', "Unternehmen");
    formdataProject.append('MMERGE16', "Position");
    formdataProject.append('MMERGE17', "Vorname");
    formdataProject.append('MMERGE2', "Nachname");
    formdataProject.append('EMAIL', "Email");

    let response = await this.http.jsonp('https://greif-velox.us11.list-manage.com/subscribe/post-json?u=ee5294e6871f97c4e8f928aee&id=2fc6be2a58&MMERGE1='+company+'&MMERGE16='+position+'&MMERGE17='+forname+'&MMERGE2='+lastname+'&EMAIL='+email+'&b_ee5294e6871f97c4e8f928aee_2fc6be2a58=&subscribe=Subscribe&_=1623136061441&c=?','callback').toPromise()
    console.log(response)
    return response;
  }


  sendPostRequest(data: any): Observable<any> {
    return this.http.post<any>(this._serviceURL, 'data=' + JSON.stringify(data), this.httpOptions);
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };
}
