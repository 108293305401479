import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RdosService } from '../services/rdos.service';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-rdos-control',
  templateUrl: './rdos-control.component.html',
  styleUrls: ['./rdos-control.component.scss']
})
export class RdosControlComponent implements OnInit {

  constructor(public _rdosService: RdosService, private activatedRoute: ActivatedRoute, private router: Router, private vpController: ViewportScroller) { }
  language;
  showOtherFlag: boolean;

  ngOnInit() {

    this.activatedRoute.queryParams.subscribe(params => {
      if (params['language'] != null && params['language'] != undefined) {
        this.language = params['language']
        this.showOtherFlag = false;
        this._rdosService.setLanguage(this.language);
        this.language = this._rdosService.currentLanguage;
      }
      this.language = this._rdosService.currentLanguage;

    }); console.log(this.language);
  }

  ngAfterViewInit(){
    this.scroll("start")
  }
  scroll(id){
    const elmnt = document.getElementById(id);
    const height = elmnt.getBoundingClientRect().height; //wrong value - gets 1735

    var topOfElement = height + 500;
    setTimeout(() => window.scroll({ top: topOfElement, behavior: "smooth" }), 100);
}
  setControlEx(boolVal) {
    this._rdosService.setIsEx(boolVal);
    this.routeToRDOS();

  }
  changeLanguage(event) {
    this.language = event
    this.showOtherFlag = false;
    this.router.navigate([], { queryParams: { language: event } })

    this._rdosService.setLanguage(this.language);
  }
  openLanguages() {
    this.showOtherFlag = true;
  }
  closeLanguages() {
    this.showOtherFlag = false;
  }
  routeToRDOS() {
    if (this._rdosService.currentLanguage == 'de') {
      this.router.navigate(['/rdos'], { queryParams: { language: "de" }, fragment: 'stagex' })
    } else {
      this.router.navigate(['/rdos'], { queryParams: { language: "en" }, fragment: 'stagex' })
    }
  }
}
