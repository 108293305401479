<div class="menu">
    <ul id="menu-list">
        <li><a href="https://www.greif-velox.com/"><div class="logo"></div></a></li>
        <li class="smallHide"><a href="tel:+4945153030" ><div id="phone-item">0451 53 03 0</div></a></li>
        <li class="smallHide"><a href="https://www.greif-velox.com/produkte/fluessigkeitsabfuellung/paletten-abfuellanlagen/">{{_rdosService._rdosConfig.pallets}}</a></li>
        <li class="smallHide"><a href="https://www.greif-velox.com/produkte/fluessigkeitsabfuellung/kanister-abfuellanlagen/">{{_rdosService._rdosConfig.canister}}</a></li>
        <li class="smallHide"><a href="https://www.greif-velox.com/produkte/fluessigkeitsabfuellung/fassabfuellanlagen/">{{_rdosService._rdosConfig.barrels}}</a></li>
        <li class="smallHide"><a href="https://www.greif-velox.com/produkte/fluessigkeitsabfuellung/ibc-abfuellanlagen/">{{_rdosService._rdosConfig.ibc}}</a></li>


        <li class="language">
            <ng-container *ngIf="language== 'en'; else germanTemplate">
                <button  class="language_button flag-button"(focus)="openLanguages()" (focusout)="closeLanguages()">
                    <span class="british_flag" ><img class="flag" src="../../assets/british_flag.jpg"></span>
                </button>
                <button class="float-bottom flag-button" *ngIf="showOtherFlag == true" (mousedown)="changeLanguage('de')">
                    <span class="german_flag"><img class="flag" src="../../assets/german_flag.jpg"></span>
                </button>
            </ng-container>
            <ng-template #germanTemplate>
                <button class="language_button flag-button" (focus)="openLanguages()" (focusout)="closeLanguages()">
                    <span class="german_flag"><img class="flag" src="../../assets/german_flag.jpg"></span>
                </button>
                <button  class="float-bottom flag-button" *ngIf="showOtherFlag == true" (mousedown)="changeLanguage('en')">
                    <span class="german_flag " ><img class="flag" src="../../assets/british_flag.jpg"></span>
                </button>
            </ng-template>


        </li>
        <li class="smallShow"><span>
            <img class="hamburger" src="../../assets/hamburger.svg">
            <div class="dropdown-content">
                <ul id="menu-list" class="menu-list2">
                    <a href="https://www.greif-velox.com/produkte/fluessigkeitsabfuellung/paletten-abfuellanlagen/">{{_rdosService._rdosConfig.pallets}}</a>
                    <a href="https://www.greif-velox.com/produkte/fluessigkeitsabfuellung/kanister-abfuellanlagen/">{{_rdosService._rdosConfig.canister}}</a>
                    <a href="https://www.greif-velox.com/produkte/fluessigkeitsabfuellung/fassabfuellanlagen/">{{_rdosService._rdosConfig.barrels}}</a>
                    <a href="https://www.greif-velox.com/produkte/fluessigkeitsabfuellung/ibc-abfuellanlagen/">{{_rdosService._rdosConfig.ibc}}</a>
                </ul>
              </div>
        </span></li>

        <li class="contact"><a href="https://www.greif-velox.com/kontakt/#c403">{{_rdosService._rdosConfig.contact}}</a></li>
    </ul>
</div>

<div class="stage stage-1" >
    <div class="stage-product-image"></div>
    <div class="stage-content-wrapper margin-bottom">
        <div class="stage-headline">
            <span class="stage-headline-light">{{_rdosService._rdosConfig.headLine}}</span>
            <span class="stage-headline-dark"> {{_rdosService._rdosConfig.headLine2}}</span>
        </div>
        <div [ngClass]="language == 'en' ? 'stage-cert-en' : 'stage-cert' "></div>
        <div class="stage-subtitle" [innerHTML] ="_rdosService._rdosConfig.subLine"></div>

    </div>
</div>

<ng-template #list1>
        <span *ngFor="let item of _rdosService._rdosConfig.neededElements">{{item}}<br></span>
</ng-template>

<!-- <ng-template #list2>
    <span *ngFor="let item of _rdosService._rdosConfig.defaultElements">{{item}}<br></span>
</ng-template> -->

<div class="container pt-5">
    <div class="row">
        <div class="col-md-12 py-md-5 col-xl-12 py-xl-0 col-cat">
            <!--<div class="h2-wrapper">-->

                <!--<p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras sit amet risus bibendum, lobortis nulla eu, finibus sapien. Sed eu nibh quam.
                </p>-->
            <!--</div>-->
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 py-md-5 col-xl-12 py-xl-0 col-cat">
            <div class="col-cat-wrapper">
                <div class="row">
                    <div class="col-6" [ngbTooltip]="list1" placement="right">
                        <div class="h2-wrapper">
                            <h2>{{_rdosService._rdosConfig.basic_rdos}}</h2>
                        </div>
                        <span class="h2-subtitle"></span><br>
                        <span class="h2-subtitle"  [innerHTML]="_rdosService._rdosConfig.basic_rdos_subline"><br></span>
                    </div>
                    <div class="col-6" [ngbTooltip]="list1" placement="left">
                        <div class="img-wrapper">
                            <img class="base-img" src="../../assets/R-DOS_trans.png"><br>
                        </div>
                    </div>
                    <!-- <ul>
                        <li *ngFor="let item of _rdosService._rdosConfig.neededElements">{{item}}</li>
                    </ul> -->
                    <!--
                    <ul>
                        <li></li>
                    </ul>
                    <div class="underline"></div>-->
                    <!-- <div *ngIf="_rdosService._rdosConfig.showNeededElementsPrice">Preis: <b>{{_rdosService._rdosConfig.showNeededElementsPrice}} €</b></div> -->
                    <!-- <div class="articleNumber" *ngIf="_rdosService._rdosConfig.neededElementsNumber">Artikelnummer: <b>{{_rdosService._rdosConfig.neededElementsNumber}}</b></div> -->
                </div>
            </div>
        </div>
        <!-- <div class="col-md-12 py-md-5 col-xl-5 py-xl-0 col-cat">
            <div class="col-cat-wrapper" [ngbTooltip]="list2" placement="bottom" >
                <div class="h2-wrapper">
                    <h2>{{_rdosService._rdosConfig.radial}}</h2>
                </div>
                <span class="h2-subtitle"></span><br>
                <span class="h2-subtitle" [innerHTML]="_rdosService._rdosConfig.radial_subline"></span>
                <div class="underline"></div>
           </div>
        </div> -->
    </div>
</div>

<div class="container py-5">
    <div class="row justify-content-center pt-5">
        <span class="stage-headline">{{_rdosService._rdosConfig.packages}}</span>
    </div>
    <div class="row justify-content-center">
        <span class="stage-subheadline">{{_rdosService._rdosConfig.nonExEx}}</span>
    </div>
</div>

<div class="container container-cat container-control no-bg-color my-5">
    <div class="row">
        <div class="col-sm-12 pb-sm-5 pb-xl-0 offset-xl-2 col-xl-4 d-flex justify-content-center clickable"(click)="setControlEx(true)" routerLinkActive="router-link-active" >
            <div class="selection-content" [ngbTooltip]="" placement="right">
                <div class="catNumber">2</div>
                <div class="selection-image image-control-1" id="start"></div>
                <div class="selection-headline">{{_rdosService._rdosConfig.nonEx}}</div>
                <!-- <div class="selection-text">Wegmesssensor Hub 1200,ex-frei</div> -->
                <!-- <div class="selection-text">Non-Ex(, Buy): inkl. Stromlaufplänen, Artikel(summen)stückliste und "06999801 Schaltschrank Fremdfertigung"</div> -->
                <div class="selection-select-btn">{{_rdosService._rdosConfig.choose}}</div>
            </div>
        </div>
        <div class="col-sm-12 col-xl-4 d-flex justify-content-center clickable" (click)="setControlEx(false)" routerLinkActive="router-link-active" >
            <div class="selection-content" [ngbTooltip]="" placement="left">
                <div class="selection-image image-control-2"></div>
                <div class="selection-headline">{{_rdosService._rdosConfig.ex}}</div>
                <!-- <div class="selection-text">Transsonarer-Wegaufnehmer</div> -->
                <!-- <div class="selection-text">Ex: Ex-Variante, inkl. Stromlaufplänen, Artikel(summen)stückliste und "06999801 Schaltschrank Fremdfertigung"</div> -->
                <div class="selection-select-btn">{{_rdosService._rdosConfig.choose}}</div>
            </div>
        </div>
    </div>
</div>
<footer class="py-5"></footer>
