import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RdosService } from '../services/rdos.service';

@Component({
  selector: 'app-dos',
  templateUrl: './dos.component.html',
  styleUrls: ['./dos.component.scss']
})
export class DosComponent implements OnInit {
  language;
    showOtherFlag: boolean;

  constructor(public _rdosService: RdosService,private activatedRoute: ActivatedRoute,protected readonly router: Router) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      if(params['language'] != null && params['language'] != undefined ){
        this.language = params['language']
        this.showOtherFlag = false;
        this._rdosService.setLanguage(this.language);
        this.language = this._rdosService.currentLanguage;
      }
      this.language = this._rdosService.currentLanguage;
      this._rdosService.setLanguage(this.language);

  });
  }

  scrollToElement($element): void {
    $element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }
  changeLanguage(event){
    this.language = event
    this.showOtherFlag = false;
    this.router.navigate([],{ queryParams: { language: event}})

    this._rdosService.setLanguage(this.language);
 }
 openLanguages(){
     this.showOtherFlag = true;
 }
 closeLanguages() {
    this.showOtherFlag = false;
 }
 routeToRDOSCon(){
  if(this._rdosService.currentLanguage == 'de'){
    this.router.navigate(['/rdos-control'],{ queryParams: { language: "de"}})
  }else{
    this.router.navigate(['/rdos-control'],{ queryParams: { language: "en"}})
  }
 }
 routeToLater() {
  if(this._rdosService.currentLanguage == 'de'){
    this.router.navigate(['/later'],{ queryParams: { language: "de"}})
  }else{
    this.router.navigate(['/later'],{ queryParams: { language: "en"}})
  }
 }
}
