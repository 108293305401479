import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RDos } from '../models/rdos.model';
import { RDosEn } from '../models/rdos.model.english'
@Injectable({
    providedIn: 'root'
})
export class RdosService {

    public _rdosConfig;
    public _rdosConfigEn;
    public currentLanguage;
    constructor(private router: Router,private activatedRoute: ActivatedRoute) {
        console.log(this.currentLanguage);
        if (this.currentLanguage == "en") {
            this.currentLanguage = "en";
            this._rdosConfig = new RDosEn();
        } else if(this.currentLanguage == "de") {
            this.currentLanguage = "de"
            this._rdosConfig = new RDos();
        } else{
                let language = this.activatedRoute.snapshot.queryParamMap.get('language');
                if(language != null && language != undefined ){
                    console.log("test")
                    if(language == "de"){
                        this._rdosConfig = new RDos();
                    }else{
                        this._rdosConfig = new RDosEn();
                    }
                  this.currentLanguage = language
                  this.router.navigate(['/'], { queryParams: { language: language } })
                }else{
                    this.currentLanguage = "de"
                    this._rdosConfig = new RDos();
                    this.router.navigate(['/'], { queryParams: { language: "de" } })   
                }
        }
    }

    getRDosConfig() {
        return this._rdosConfig;
    }

    setIsEx(boolVal) {
        this._rdosConfig.isEx = boolVal;
        console.log('Set Ex/Non-Ex to : isEx=' + this._rdosConfig.isEx);
    }

    getIsEx() {
        console.log(this._rdosConfig.isEx)
        return this._rdosConfig.isEx;
    }
    setLanguage(language) {
        let currentExVal = this._rdosConfig.isEx;
        switch (language) {
            case "de":
                this._rdosConfig = new RDos();
                this._rdosConfig.isEx = currentExVal;
                this.currentLanguage = language;
                this._rdosConfig.language = language;
                break;
            case "en":
                this._rdosConfig = new RDosEn();
                this._rdosConfig.isEx = currentExVal
                this.currentLanguage = language;
                this._rdosConfig.language = language;
                break;
            default:
                this.router.navigate(['/'], { queryParams: { language: "de" } })
        }
    }
}
