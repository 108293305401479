
export class RDosEn {
    id: number = 1;
    name: string = 'R-DOS';
    firstname: string = '';
    lastname: string = '';
    telephone: string = '';
    company: string = '';
    email: string = '';
    position: string = '';
    street: string = '';
    postal_code: string = '';
    city: string = '';
    country: string = '';
    form_of_address: string = '';
    privacy: boolean = false;
    newsletter: boolean = false;


    //All sites
    headLine: string = "Greif Velox"
    headLine2: string = "System configurator "
    subLine: string = "The Greif-Velox system configurator gives you the option of individually configuring our innovative systems for filling solids and liquids. In just a few steps we will guide you to describe your demand and request your proposal. <br> <br> We are currently working on making our configuration tool compatible with more systems. If you can't find the type of system you're looking for, contact us directly."
    ger: string = "GER";
    en: string = "ENG"
    start: string = "START"
    pallets: string = "Pallets";
    canister: string = "Canister";
    barrels: string = "Barrels";
    ibc: string = "IBC"
    contact: string = "Contact"
    inputPlaceholder: string = 'Input..';

    //DOS
    dosHeadLine: string = "Choose your"
    dosSubLine: string = "Pallet filling system"
    dosCoose: string = "Choose System"
    dosRDOS: string = "Radial filling systems"
    dosKDOS: string = "Coordination filling systems"
    dosADOS: string = "Automated filling systems"

    //RDOS-CONTROL
    basic_rdos: string = "Greif-Velox Radial filling system R-Dos"
    basic_rdos_subline: string = "Stainless steel model"
    radial: string = "Radial filling systems"
    radial_subline: string = "Stainless steel model"
    packages: string = "Choose from our two packages"
    nonExEx: string = "Controller for hazardous and non-hazardous areas"
    choose: string = "Make a selection";
    ex: string = "Controller for hazardous areas"
    nonEx: string = "Controller for non-hazardous areas"


    //RDOS
    additionalBtn: string = "Product details (optional)";
    offerTitle: string = "PDF quotations";
    offerheadLine: string = "Create your"
    isEx: boolean = false;
    smallHint: string = 'You can add more products by clicking.';
    neededElementsTitle: string = 'Pallet filling system';
    neededElements: string[] = [
        'Filling unit BLU 1200',
        'Extraction hood with spout ø88.9 right',
        'Motorised BHA, 800mm',
        'Pressure cylinder',
        'Window made from conductive Europlex',
        'Valve lift unit BLU/1200/800',
        'Window made from Plexiglas',
        'Maintenance unit with oiler (with motorised BHA)',
        'Distance measuring unit',
        'P-controller for motorised/semi-automatic BHA',
        'Maintenance unit with oiler',
        'Vertical column R-DOS for clearance height 1300 with pit and floor scales',
        'Beam R-DOS',
        'Control box mount for control box 300x400',
        'Handle',
        'P-controller R-DOS brake',
    ]

    neededElementsPrice: number = 20000.00;
    showNeededElementsPrice: boolean = false;
    neededElementsNumber: string = '16075357';
    showArtNr: false;

    defaultElementsTitle: string = 'Radial filling systems';
    defaultElements: string[] = [
        'Beam movement path 120',
        'Stainless steel vertical column for conveying height 0–85 mm (pit scales or floor scales)',
        'Height adapter for vertical column',
        'Conveying height 500 = standard conveyor system',
        'Handle for moving the Y-axis'
    ]

    defaultElementsPrice: number = 1000.11;
    showDefaultElementsPrice: boolean = false;
    defaultElementsNumber: string = '12345678';


    //Combine of needed and default
    combineElementsTitle: string = 'Basic Greif-Velox R-DOS';
    combineElementsPrice: number = 20.360 ;
    combineElementsNumber: string = '16075357';

    // SEND
    mailSent: string = 'Thank you very much for your inquiry. Your request will be reviewed form one on our experts and send to you shortly. If you want to contact us in the meantime please call us at: <a href="tel:+4945153030" >+49 451 53 03 0</a>, E-Mail: <a href="mailto:info@greif-velox.com?">info@greif-velox.com</a> or contact us via our contact section.'


    // LATER 
    konfiguration_soon: string ="A configuration of this filling system will be available soon."
    konfiguration_soon_subline: string ="Greif Velox filling systems."

    // POPUP
    popupHeader: string = "Information relevant to the offer"    
    enterCompany: string = "Company Name"
    enterPosition: string = "Position"
    enterTitle: string = "Form Of Address"
    enterFirstName: string = "First Name"
    enterLastName: string = "Last Name"
    enterPhoneNumber: string = "Phone Number"
    enterStreet: string = "Street"
    enterPostalCode: string = "Postal Code"
    enterCity: string = "City"
    enterCountry: string = "Country"
    enterEmail: string = "Email-Adress"
    checkPolicy1: string = "You agree that your data may be used to process your request. Further information and revocation instructions can be found in the "
    checkPolicy2: string = "data protection"
    checkNewsletter: string = "I would like to be included in the Greif Velox newsletter."
    send: string = "Send"
    
    selectMale: string = "Mr."
    selectWoman: string = "Mrs."

    //selectionType: 'eitherOr' , 'count', 'if', 'optional'

    rdosHeadLine: string = "Consider your own components for "
    rdosSubLine1: string = "control hazardous areas"
    rdosSubLine2: string = "control non-hazardous areas"
    rdosHaveToSelect: string = "These components must be chosen"
    rdosFooter: string = "Special colors are calculated on request."
    emptyRequiredFields: string = "Please fill out all fields marked with '*' and accept the privacy policy."

    enterNumber: string = "Enter - Number"

    equipmentCategories: {}[] = [

        {//0
            selectionType: 'automatic',
            catName: '',
            catSubname: 'Controller depending on the package selected:',
            catSubnameAddon: '',
            catSubnameStar: true,
            catNumber: '3',
            showArtNr: false,
            equipment: [
                {
                    equipmentName: 'Transsonar displacement transducer',
                    countableIsRelatedToEquipmentCategoriesIndex: null,
                    countableIsRelatedToEquipmentIndex: null,
                    price: 2370.00,
                    artNr: '05070409',
                    showArtNr: false,
                    enabled: true,
                    checked: false,
                },
                {
                    equipmentName: 'Ex: incl. circuit diagrams and control cabinet ',
                    countableIsRelatedToEquipmentCategoriesIndex: null,
                    countableIsRelatedToEquipmentIndex: null,
                    price: 33850.00,
                    artNr: '16075703',
                    showArtNr: false,
                    enabled: true,
                    checked: false,
                },
                {
                    equipmentName: 'Displacement gauge lift 1200, for non-hazardous areas',
                    countableIsRelatedToEquipmentCategoriesIndex: null,
                    countableIsRelatedToEquipmentIndex: null,
                    price: 1320,
                    artNr: '16075244',
                    showArtNr: false,
                    enabled: true,
                    checked: false,
                },
                {
                    equipmentName: 'For non-hazardous areas: incl. circuit diagrams and control cabinet',
                    countableIsRelatedToEquipmentCategoriesIndex: null,
                    countableIsRelatedToEquipmentIndex: null,
                    price: 15385.00,
                    artNr: '16075702',
                    showArtNr: false,
                    enabled: true,
                    checked: true,
                }
            ]
        },
        {//2
            selectionType: 'count',
            catName: '',
            catSubname: 'Determine the number of dosing valves:',
            catSubnameAddon: '',
            catSubnameStar: true,
            catNumber: '4',
            showArtNr: false,
            equipment: [
                {
                    equipmentName: 'Dosing valve BLV25/1200, Viton O-rings',
                    equipmentToolTip: 'Filling pipe ø33.7; without pitot tube; with flange DN50. O-rings made of Kalrez and FEP-encapsulated Viton, suitable for food.',
                    countableIsRelatedToEquipmentCategoriesIndex: null,
                    countableIsRelatedToEquipmentIndex: null,
                    price: 1250,
                    artNr: '16075382',
                    showArtNr: false,
                    countable: true,
                    showPlaceholder: true,
                    count: null,
                    checked: false,
                    enabled: true
                },
                {
                    equipmentName: 'Dosing valve BLV32/1200, Kalrez O-rings',
                    equipmentToolTip: 'Filling pipe ø42.4; without pitot tube; with flange DN50. O-rings made of Kalrez and FEP-encapsulated Viton, suitable for food.',
                    countableIsRelatedToEquipmentCategoriesIndex: null,
                    countableIsRelatedToEquipmentIndex: null,
                    price: 1780,
                    artNr: '16075336',
                    showArtNr: false,
                    countable: true,
                    showPlaceholder: true,
                    count: null,
                    checked: false,
                    enabled: true
                },
                {
                    equipmentName: 'Dosing valve BLV50/1200, Viton O-rings',
                    equipmentToolTip: 'Filling pipe ø60.3; without pitot tube; with flange DN50. O-rings made of Kalrez and FEP-encapsulated Viton, suitable for food.',
                    countableIsRelatedToEquipmentCategoriesIndex: 4,
                    countableIsRelatedToEquipmentIndex: 1,
                    price: 2655,
                    artNr: '16075386',
                    showArtNr: false,
                    countable: true,
                    showPlaceholder: true,
                    count: null,
                    checked: false,
                    enabled: true
                }
            ]
        },
        {//3
            selectionType: 'count',
            catName: '',
            catSubname: 'Determine the number of wiper rings:',
            catSubnameAddon: '',
            catSubnameStar: true,
            catNumber: '5',
            showArtNr: false,
            equipment: [
                {
                    equipmentName: 'Wiper ring DN 25',
                    equipmentToolTip: 'made of PTFE with O-ring made of FEP-encapsulated Viton.',
                    countableIsRelatedToEquipmentCategoriesIndex: null,
                    countableIsRelatedToEquipmentIndex: null,
                    price: 100.00,
                    artNr: '16075246',
                    showArtNr: false,
                    countable: true,
                    showPlaceholder: true,
                    count: null,
                    checked: false,
                    enabled: true
                },
                {
                    equipmentName: 'Wiper ring DN 32',
                    equipmentToolTip: 'made of PTFE with O-ring made of FEP-encapsulated Viton.',
                    countableIsRelatedToEquipmentCategoriesIndex: 4,
                    countableIsRelatedToEquipmentIndex: 1,
                    price: 100.00,
                    artNr: '16033310',
                    showArtNr: false,
                    countable: true,
                    showPlaceholder: true,
                    count: null,
                    checked: false,
                    enabled: true
                },
                {
                    equipmentName: 'Wiper ring DN 50',
                    equipmentToolTip: 'made of PTFE with O-ring made of FEP-encapsulated Viton.',
                    countableIsRelatedToEquipmentCategoriesIndex: 4,
                    countableIsRelatedToEquipmentIndex: 0,
                    price: 180.00,
                    artNr: '16075290',
                    showArtNr: false,
                    countable: true,
                    showPlaceholder: true,
                    count: null,
                    checked: false,
                    enabled: true
                }
            ]
        },
        {//4
            selectionType: 'eitherOr',
            catName: '',
            catSubname: 'Choose a drip setting.',
            // catSubnameAddon: '(selected automatically)',
            catSubnameStar: false,
            catNumber: '6',
            showArtNr: false,
            selectionSubType: 'if',
            // catSubSubname: 'Select a pneumatic drip catcher:',
            catSubSubnameAddon: '',
            catSubSubnameStar: true,
            equipment: [
                {//0
                    equipmentName: 'mechanical drip catcher',
                    // equipmentToolTip: 'Bacon ipsum dolor amet beef sausage flank kevin ham hock filet mignon t-bone biltong. Biltong turkey tri-tip landjaeger rump short ribs, beef ribs corned beef burgdoggen doner andouille tail t-bone ham hock.',
                    countableIsRelatedToEquipmentCategoriesIndex: null,
                    countableIsRelatedToEquipmentIndex: null,
                    price: 420.00,
                    artNr: '16068952',
                    showArtNr: false,
                    checked: false,
                    enabled: true
                },
                {//1
                    equipmentName: 'Pneumatic drip catcher with PTFE tray',
                    // equipmentToolTip: 'Bacon ipsum dolor amet beef sausage flank kevin ham hock filet mignon t-bone biltong. Biltong turkey tri-tip landjaeger rump short ribs, beef ribs corned beef burgdoggen doner andouille tail t-bone ham hock.',
                    countableIsRelatedToEquipmentCategoriesIndex: null,
                    countableIsRelatedToEquipmentIndex: null,
                    price: 435.00,
                    artNr: '16075339',
                    showArtNr: false,
                    checked: false,
                    enabled: true
                },
                {//2
                    equipmentName: 'Pneumatic drip catcher with disposable aluminium tray',
                    // equipmentToolTip: 'Bacon ipsum dolor amet beef sausage flank kevin ham hock filet mignon t-bone biltong. Biltong turkey tri-tip landjaeger rump short ribs, beef ribs corned beef burgdoggen doner andouille tail t-bone ham hock.',
                    countableIsRelatedToEquipmentCategoriesIndex: null,
                    countableIsRelatedToEquipmentIndex: null,
                    price: 410.00,
                    artNr: '16075338',
                    showArtNr: false,
                    checked: false,
                    enabled: true
                }
            ]
        },
        {//5
            selectionType: 'automatic',
            catName: '',
            catSubname: 'Manual earth clamps: ',
            catSubnameAddon: '(selected automatically)',
            catSubnameStar: false,
            catNumber: '7',
            showArtNr: false,
            equipment: [
                {
                    equipmentName: 'Earth clamps with evaluation unit',
                    countableIsRelatedToEquipmentCategoriesIndex: null,
                    countableIsRelatedToEquipmentIndex: null,
                    price: 2250.00,
                    artNr: '16075356',
                    showArtNr: false,
                    checked: false,
                    enabled: false
                }
            ]
        },
    ]

    optionalEquipmentCategories: {}[] = [
        {//0
            selectionType: 'eitherOr',
            catName: 'Optional accessories',
            catSubname: 'Choose scales:',
            catSubnameAddon: '(optional)',
            catSubnameStar: false,
            catNumber: '8',
            showArtNr: false,
            selectionSubType: 'optional',
            catSubSubname: 'Select other optional components: ',
            catSubSubnameAddon: '',
            catSubSubnameStar: false,
            equipment: [
                {//0
                    equipmentName: 'Base R-DOS [connects to the conveyor system]',
                    equipmentToolTip: 'to raise the vertical column by 500 mm. Including operator platform between the conveyor system and control cabinet at the vertical column.',
                    countableIsRelatedToEquipmentCategoriesIndex: null,
                    countableIsRelatedToEquipmentIndex: null,
                    price: 1105.00,
                    artNr: '16075305',
                    showArtNr: false,
                    checked: false,
                    enabled: true
                },
                {//1
                    equipmentName: 'Pit scales 1500x1250',
                    equipmentToolTip: 'Load receptor 1500x1250, made of stainless steel, including stainless steel pit frames, can be calibrated, dual interval scale, load-bearing capacity: 4000 kg, weighing range: 600/1500 kg, increments: 200/500 g, load cells: stainless steel, IP68/69k (SB4-10kN-C3 MI7,5), load cell cable: 6 m PUR, junction box: external – Flintec KPK-4 made of polyester, suitable for hazardous and non-hazardous areas.',
                    countableIsRelatedToEquipmentCategoriesIndex: null,
                    countableIsRelatedToEquipmentIndex: null,
                    price: 6100.00,
                    artNr: '05320335',
                    showArtNr: false,
                    checked: false,
                    enabled: true
                },
                {//2
                    equipmentName: 'Floor scales 1500x1250',
                    equipmentToolTip: 'Load receptor 1500x1250, made of stainless steel, including 3-sided impact protection made of standard steel, zone 1 hazardous areas, II 2G ib T4, incl. protection against dislocation, can be calibrated, dual interval scale, load-bearing capacity: 3500 kg, weighing range: 600/1500 kg, increments: 200/500 g, load cells: stainless steel, IP68/69k (SB4-10kN-C3 MI7,5-Ex), load cell cable: 6 m PUR, junction box: external – Flintec KPK-4 made of polyester, suitable for hazardous and non-hazardous areas.',
                    countableIsRelatedToEquipmentCategoriesIndex: null,
                    countableIsRelatedToEquipmentIndex: null,
                    price: 6520.00,
                    artNr: '16057922',
                    showArtNr: false,
                    checked: false,
                    enabled: true
                },
                {//3
                    equipmentName: 'U-frame scales 1250x1450',
                    equipmentToolTip: 'U-frame scales 1250x1450x80, made of stainless steel, incl. front impact protection made of 1.4301, can be calibrated as an automatic scale, for ATEX ex 2G ib hazardous areas, dual interval 2x3000d, weighing range based on automatic scale: 20–600/1500 kg.',
                    countableIsRelatedToEquipmentCategoriesIndex: null,
                    countableIsRelatedToEquipmentIndex: null,
                    price: 9880.00,
                    artNr: '16075376',
                    showArtNr: false,
                    checked: false,
                    enabled: true
                }
            ],
            subEquipment: [
                {//4
                    equipmentName: 'Laser pointer for hazardous areas',
                    equipmentToolTip: 'as an aiming device instead of chain, produces a green point in front of the bunghole. Suitable hazardous and non-hazardous areas.',
                    countableIsRelatedToEquipmentCategoriesIndex: null,
                    countableIsRelatedToEquipmentIndex: null,
                    price: 2250.00,
                    artNr: '16075389',
                    showArtNr: false,
                    checked: false,
                    enabled: true
                },
                {//5
                    equipmentName: 'Beam with screwing tool',
                    equipmentToolTip: 'swivel-mounted beam on the vertical column, including ATEX screwing tool, also for non-hazardous areas, suitable for 2" Tri-Sure steel caps ',
                    countableIsRelatedToEquipmentCategoriesIndex: null,
                    countableIsRelatedToEquipmentIndex: null,
                    price: 7145.00,
                    artNr: '16075365',
                    showArtNr: false,
                    checked: false,
                    enabled: true
                },
                {//6
                    equipmentName: 'Clincher',
                    equipmentToolTip: ' to attach to the beam with the screwing tool, suitable for Tri-Sure Tabseal caps.',
                    countableIsRelatedToEquipmentCategoriesIndex: null,
                    countableIsRelatedToEquipmentIndex: null,
                    price: 2995.00,
                    artNr: '16075366',
                    showArtNr: false,
                    checked: false,
                    enabled: true
                },
                {//7
                    equipmentName: 'Valve parking stands for 2 valves',
                    countableIsRelatedToEquipmentCategoriesIndex: null,
                    countableIsRelatedToEquipmentIndex: null,
                    price: 1590.00,
                    artNr: 'NN',
                    showArtNr: false,
                    checked: false,
                    enabled: true,
                    combofield: true,
                    comboname: "Input",
                    combovalue: "",
                },
                {//8
                    equipmentName: 'Product feed',
                    equipmentToolTip: 'consisting of: mount for the ball valve on the vertical column, pilot control valve made of stainless steel, pivot joint made of stainless steel, Fluorflex product hose made of PTFE, L=3500, product hose suspension with spring balancer',
                    countableIsRelatedToEquipmentCategoriesIndex: null,
                    countableIsRelatedToEquipmentIndex: null,
                    price: 5200.00,
                    artNr: '16075398',
                    showArtNr: false,
                    checked: false,
                    enabled: true
                },
            ],
        }
    ]
    additionalEquipmentCategories:{}[] = [

        {//0
            selectionType: 'automatic',
            catName: 'Optionales Zubehör',
            catSubname: 'Additional options for products:',
            //catTooltip: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras sit amet risus bibendum, lobortis nulla eu, finibus sapien. Sed eu nibh quam. Sed quis viverra risus. Integer fermentum a dui id varius.",
            catSubnameAddon: '(optional)',
            catSubnameStar: false,
            catNumber: '9',
            showArtNr: false,
            selectionSubType: 'optional',
            addType: 'product',
            catSubSubnameAddon: '',
            catSubSubnameStar: false,
            equipment: [
                {
                    equipmentName: 'Name of product',
                    countableIsRelatedToEquipmentCategoriesIndex: null,
                    countableIsRelatedToEquipmentIndex: null,
                    price: "",
                    artNr: '',
                    showArtNr: false,
                    enabled: true,
                    checked: false,
                    textfield: true,
                    text: "",
                },
                {
                    equipmentName: 'density (kg/dm³)  ',
                    countableIsRelatedToEquipmentCategoriesIndex: null,
                    countableIsRelatedToEquipmentIndex: null,
                    price: "",
                    artNr: '',
                    showArtNr: false,
                    enabled: true,
                    checked: false,
                    textfield: true,
                    text: "",
                },
                {
                    equipmentName: 'Temperature (°C)',
                    countableIsRelatedToEquipmentCategoriesIndex: null,
                    countableIsRelatedToEquipmentIndex: null,
                    price: "",
                    artNr: '',
                    showArtNr: false,
                    enabled: true,
                    checked: false,
                    textfield: true,
                    text: "",
                },
                {
                    equipmentName: 'foaming?',
                    countableIsRelatedToEquipmentCategoriesIndex: null,
                    countableIsRelatedToEquipmentIndex: null,
                    price: "",
                    artNr: '',
                    showArtNr: false,
                    enabled: true,
                    checked: false,
                },
                {
                    equipmentName: 'toxic?',
                    countableIsRelatedToEquipmentCategoriesIndex: null,
                    countableIsRelatedToEquipmentIndex: null,
                    price: "",
                    artNr: '',
                    showArtNr: false,
                    enabled: true,
                    checked: false,
                },
                {
                    equipmentName: 'inflammable? ',
                    countableIsRelatedToEquipmentCategoriesIndex: null,
                    countableIsRelatedToEquipmentIndex: null,
                    price: "",
                    artNr: '',
                    showArtNr: false,
                    enabled: true,
                    checked: false,
                    combofield: true,
                    comboname: "flash point °C ",
                    combovalue: "",
                },
                {
                    equipmentName: 'Caustic?',
                    countableIsRelatedToEquipmentCategoriesIndex: null,
                    countableIsRelatedToEquipmentIndex: null,
                    price: "",
                    artNr: '',
                    showArtNr: false,
                    enabled: true,
                    checked: false,
                    combofield: true,
                    comboname: "pH-index",
                    combovalue: "",
                },
                {
                    equipmentName: 'Gas emission?',
                    countableIsRelatedToEquipmentCategoriesIndex: null,
                    countableIsRelatedToEquipmentIndex: null,
                    price: "",
                    artNr: '',
                    showArtNr: false,
                    enabled: true,
                    checked: false,
                },
                {
                    equipmentName: 'hardening?',
                    countableIsRelatedToEquipmentCategoriesIndex: null,
                    countableIsRelatedToEquipmentIndex: null,
                    price: "",
                    artNr: '',
                    showArtNr: false,
                    enabled: true,
                    checked: false,
                    combofield: true,
                    comboname: "freezingpoint in °C ",
                    combovalue: "",
                },
                {
                    equipmentName: 'sticky?',
                    countableIsRelatedToEquipmentCategoriesIndex: null,
                    countableIsRelatedToEquipmentIndex: null,
                    price: "",
                    artNr: '',
                    showArtNr: false,
                    enabled: true,
                    checked: false,
                },
                {
                    equipmentName: 'Viscosity (mPas) ',
                    countableIsRelatedToEquipmentCategoriesIndex: null,
                    countableIsRelatedToEquipmentIndex: null,
                    price: "",
                    artNr: '',
                    showArtNr: false,
                    enabled: true,
                    textfield: true,
                    text: "",
                    checked: false,
                },
            ]
        },
        {//2
            selectionType: '',
            catName: 'Optionales Zubehör',
            catSubname: 'Additional options for containers:',
            //catTooltip: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras sit amet risus bibendum, lobortis nulla eu, finibus sapien. Sed eu nibh quam. Sed quis viverra risus. Integer fermentum a dui id varius.",
            catSubnameAddon: '(optional)',
            catSubnameStar: false,
            catNumber: '10',
            showArtNr: false,
            selectionSubType: 'optional',
            addType: 'container',
            catSubSubnameAddon: '',
            catSubSubnameStar: false,
            equipment: [
                {
                    equipmentName: 'container name',
                    countableIsRelatedToEquipmentCategoriesIndex: null,
                    countableIsRelatedToEquipmentIndex: null,
                    price: "",
                    artNr: '',
                    showArtNr: false,
                    enabled: true,
                    checked: false,
                    textfield: true,
                    text: "",
                },
                {
                    equipmentName: 'container contents (lts)',
                    equipmentToolTip: '',
                    countableIsRelatedToEquipmentCategoriesIndex: null,
                    countableIsRelatedToEquipmentIndex: null,
                    price: "",
                    artNr: '',
                    showArtNr: false,
                    count: null,
                    checked: false,
                    textfield: true,
                    text: "",
                    enabled: true
                },
                {
                    equipmentName: 'net filling weight (kg)',
                    equipmentToolTip: '',
                    countableIsRelatedToEquipmentCategoriesIndex: null,
                    countableIsRelatedToEquipmentIndex: null,
                    price: "",
                    artNr: '',
                    showArtNr: false,
                    count: null,
                    checked: false,
                    textfield: true,
                    text: "",
                    enabled: true
                },
            ]
        },
        {//3
            selectionType: 'eitherOr',
            catName: 'Optionales Zubehör',
            catSubname: 'Additional options for pallets:',
            //catTooltip: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras sit amet risus bibendum, lobortis nulla eu, finibus sapien. Sed eu nibh quam. Sed quis viverra risus. Integer fermentum a dui id varius.",
            catSubnameAddon: '(optional)',
            catSubnameStar: false,
            catNumber: '11',
            showArtNr: false,
            selectionSubType: 'optional',
            addType: 'pallet',
            catSubSubnameAddon: '',
            catSubSubnameStar: false,
            equipment: [
                {
                    equipmentName: 'Type',
                    equipmentToolTip: '',
                    countableIsRelatedToEquipmentCategoriesIndex: null,
                    countableIsRelatedToEquipmentIndex: null,
                    price: "",
                    artNr: '',
                    showArtNr: false,
                    checked: false,
                    textfield: true,
                    text: "",
                    enabled: true
                },
                {
                    equipmentName: 'Length  (mm)',
                    equipmentToolTip: '',
                    countableIsRelatedToEquipmentCategoriesIndex: 4,
                    countableIsRelatedToEquipmentIndex: 1,
                    price: "",
                    artNr: '',
                    showArtNr: false,
                    checked: false,
                    textfield: true,
                    text: "",
                    enabled: true
                },
                {
                    equipmentName: 'Width  (mm)',
                    equipmentToolTip: '',
                    countableIsRelatedToEquipmentCategoriesIndex: 4,
                    countableIsRelatedToEquipmentIndex: 0,
                    price: "",
                    artNr: '',
                    showArtNr: false,
                    checked: false,
                    textfield: true,
                    text: "",
                    enabled: true
                },
                {
                    equipmentName: 'Height  (mm)',
                    equipmentToolTip: '',
                    countableIsRelatedToEquipmentCategoriesIndex: 4,
                    countableIsRelatedToEquipmentIndex: 1,
                    price: "",
                    artNr: '',
                    showArtNr: false,
                    checked: false,
                    textfield: true,
                    text: "",
                    enabled: true
                },
                {
                    equipmentName: 'Weight  (kg)',
                    equipmentToolTip: '',
                    countableIsRelatedToEquipmentCategoriesIndex: 4,
                    countableIsRelatedToEquipmentIndex: 1,
                    price: "",
                    artNr: '',
                    showArtNr: false,
                    checked: false,
                    textfield: true,
                    enabled: true,
                    text: "",
                },
            ]
        }
    ]
}