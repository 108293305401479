<div class="menu">
    <ul id="menu-list">
        <li><a href="https://www.greif-velox.com/">
                <div class="logo"></div>
            </a></li>
        <li class="smallHide"><a href="tel:+4945153030">
                <div id="phone-item">0451 53 03 0</div>
            </a></li>
        <li class="smallHide"><a
                href="https://www.greif-velox.com/produkte/fluessigkeitsabfuellung/paletten-abfuellanlagen/">{{_rdosService._rdosConfig.pallets}}</a>
        </li>
        <li class="smallHide"><a
                href="https://www.greif-velox.com/produkte/fluessigkeitsabfuellung/kanister-abfuellanlagen/">{{_rdosService._rdosConfig.canister}}</a>
        </li>
        <li class="smallHide"><a
                href="https://www.greif-velox.com/produkte/fluessigkeitsabfuellung/fassabfuellanlagen/">{{_rdosService._rdosConfig.barrels}}</a>
        </li>
        <li class="smallHide"><a
                href="https://www.greif-velox.com/produkte/fluessigkeitsabfuellung/ibc-abfuellanlagen/">{{_rdosService._rdosConfig.ibc}}</a>
        </li>


        <li class="language">
            <ng-container *ngIf="language== 'en'; else germanTemplate">
                <button class="language_button flag-button" (focus)="openLanguages()" (focusout)="closeLanguages()">
                    <span class="british_flag"><img class="flag" src="../../assets/british_flag.jpg"></span>
                </button>
                <button class="float-bottom flag-button" *ngIf="showOtherFlag == true"
                    (mousedown)="changeLanguage('de')">
                    <span class="german_flag"><img class="flag" src="../../assets/german_flag.jpg"></span>
                </button>
            </ng-container>
            <ng-template #germanTemplate>
                <button class="language_button flag-button" (focus)="openLanguages()" (focusout)="closeLanguages()">
                    <span class="german_flag"><img class="flag" src="../../assets/german_flag.jpg"></span>
                </button>
                <button class="float-bottom flag-button" *ngIf="showOtherFlag == true"
                    (mousedown)="changeLanguage('en')">
                    <span class="german_flag "><img class="flag" src="../../assets/british_flag.jpg"></span>
                </button>
            </ng-template>


        </li>
        <li class="smallShow"><span>
                <img class="hamburger" src="../../assets/hamburger.svg">
                <div class="dropdown-content">
                    <ul id="menu-list" class="menu-list2">
                        <a
                            href="https://www.greif-velox.com/produkte/fluessigkeitsabfuellung/paletten-abfuellanlagen/">{{_rdosService._rdosConfig.pallets}}</a>
                        <a
                            href="https://www.greif-velox.com/produkte/fluessigkeitsabfuellung/kanister-abfuellanlagen/">{{_rdosService._rdosConfig.canister}}</a>
                        <a
                            href="https://www.greif-velox.com/produkte/fluessigkeitsabfuellung/fassabfuellanlagen/">{{_rdosService._rdosConfig.barrels}}</a>
                        <a
                            href="https://www.greif-velox.com/produkte/fluessigkeitsabfuellung/ibc-abfuellanlagen/">{{_rdosService._rdosConfig.ibc}}</a>
                    </ul>
                </div>
            </span></li>

        <li class="contact"><a href="https://www.greif-velox.com/kontakt/#c403">{{_rdosService._rdosConfig.contact}}</a>
        </li>
    </ul>
</div>

<div class="stage stage-1"  id="stagex">
    <div class="stage-product-image"></div>
    <div class="stage-content-wrapper margin-bottom">
        <div class="stage-headline">
            <span class="stage-headline-light">{{_rdosService._rdosConfig.headLine}}</span>
            <span class="stage-headline-dark"> {{_rdosService._rdosConfig.headLine2}}</span>
        </div>
        <div [ngClass]="language == 'en' ? 'stage-cert-en' : 'stage-cert' "></div>
        <div class="stage-subtitle" [innerHTML]="_rdosService._rdosConfig.subLine"></div>

    </div>
</div>
<div class="container py-5">
    <div class="row justify-content-center full-width pt-5">
        <span class="stage-headline">{{_rdosService._rdosConfig.rdosHeadLine}}</span>
    </div>
    <div class="row justify-content-center full-width">
        <span class="stage-subheadline"
            *ngIf="!_rdosService._rdosConfig.isEx">{{_rdosService._rdosConfig.rdosSubLine1}}</span>
        <span class="stage-subheadline"
            *ngIf="_rdosService._rdosConfig.isEx">{{_rdosService._rdosConfig.rdosSubLine2}}</span>
    </div>
</div>

<div class="container container-cat my-5 p-5"
    *ngFor="let equipmentCategory of _rdosService._rdosConfig.equipmentCategories">
    <div class="catNumber">{{equipmentCategory.catNumber}}</div>
    <!--<div class="item-headline" *ngIf="equipmentCategory.catName" [[innerHTML]]="equipmentCategory.catName"></div>
    <div *ngIf="equipmentCategory.catName" class="underline"></div>-->
    <div class="row subheadline" *ngIf="equipmentCategory.catSubname">
        <div class="col" ngbTooltip="{{equipmentCategory.catTooltip}}" tooltipClass="tooltip" placement="top"
            [closeDelay]="50">
            {{equipmentCategory.catSubname}} <span class="addon">{{equipmentCategory.catSubnameAddon}}</span> <span
                *ngIf="equipmentCategory.catSubnameStar" class="highlight">*</span>
        </div>
    </div>

    <div class="row item-element" *ngFor="let equipmentItem of equipmentCategory.equipment">
        <div class="col-7">
            <span [ngClass]="{'unenabled-text' : !equipmentItem.enabled}" [innerHTML]="equipmentItem.equipmentName"
                ngbTooltip="{{equipmentItem.equipmentToolTip}}" tooltipClass="tooltip" placement="right"
                [closeDelay]="50"></span>
        </div>
        <div class="col-3 d-flex justify-content-center" *ngIf="equipmentItem.showArtNr">
            <span class="artnr" [ngClass]="{'unenabled-text' : !equipmentItem.enabled}">Art.-Nr.:
                {{equipmentItem.artNr}}</span>
        </div>
        <div class="col-2 d-flex justify-content-center" [ngClass]="{'offset-3' : !equipmentItem.showArtNr}">
            <label class="checkmark-container" *ngIf="!equipmentItem.countable">
                <input type="radio" [attr.name]="equipmentCategory.catNumber"
                    (click)="toggle(equipmentItem,equipmentCategory)" name="{{equipmentCategory.catNumber}}"
                    [attr.value]="equipmentCategory.artNr" value="{{equipmentItem.artNr}}"
                    *ngIf="equipmentCategory.selectionType=='eitherOr'" [(ngModel)]="equipmentItem.checked"
                    [attr.disabled]="!equipmentItem.enabled ? '' : null" data-md-icheck
                    (change)="toggleChecked($event)" />
                <input type="checkbox" *ngIf="equipmentCategory.selectionType=='automatic'"
                    [(ngModel)]="equipmentItem.checked" [attr.disabled]="!equipmentItem.enabled ? '' : null"
                    data-md-icheck (change)="toggleChecked($event)" />
                {{equipmentItem.selectionType}}
                <span class="checkmark" [ngClass]="{'disabled' : !equipmentItem.enabled}"></span>
            </label>
            <div class="input-wrapper" *ngIf="equipmentItem.countable">
                <input class="input-container" [(ngModel)]="equipmentItem.count"
                    placeholder="{{_rdosService._rdosConfig.enterNumber}}"
                    (ngModelChange)="modelChanged(equipmentItem)" />
                <!--<span class="count-unit" #input>Stck.</span>
                <span class="count-blend" (click)="blendPlaceholder(equipmentItem)" *ngIf="equipmentItem.showPlaceholder">Anzahl - eingeben</span>-->
            </div>
        </div>
    </div>

    <div class="row subheadline subsubheadline" *ngIf="equipmentCategory.catSubSubname">
        <div class="col">
            {{equipmentCategory.catSubSubname}} <span class="addon">{{equipmentCategory.catSubSubnameAddon}}</span>
            <span *ngIf="equipmentCategory.catSubSubnameStar" class="highlight">*</span>
        </div>
    </div>

    <div class="row item-element" *ngFor="let equipmentItem of equipmentCategory.subEquipment">
        <div class="col-7">
            <span [ngClass]="{'unenabled-text' : !equipmentItem.enabled}" [innerHTML]="equipmentItem.equipmentName "
                ngbTooltip="equipmentItem.equipmentToolTip" tooltipClass="tooltip" placement="right"
                [closeDelay]="50"></span>
        </div>
        <div class="col-3 d-flex justify-content-center" *ngIf="equipmentItem.showArtNr">
            <span class="artnr" [ngClass]="{'unenabled-text' : !equipmentItem.enabled}">Art.-Nr.:
                {{equipmentItem.artNr}}</span>
        </div>
        <div class="col-2 d-flex justify-content-center" [ngClass]="{'offset-3' : !equipmentItem.showArtNr}">
            <label class="checkmark-container" *ngIf="!equipmentItem.countable">
                <input type="checkbox" [(ngModel)]="equipmentItem.checked"
                    [attr.disabled]="!equipmentItem.enabled ? '' : null" data-md-icheck
                    (change)="toggleChecked($event)" />
                <span class="checkmark" [ngClass]="{'disabled' : !equipmentItem.enabled}"></span>
            </label>
            <div class="input-wrapper" *ngIf="equipmentItem.countable">
                <input class="input-container" [(ngModel)]="equipmentItem.count"
                    placeholder="{{_rdosService._rdosConfig.enterNumber}}"
                    (ngModelChange)="modelChanged(equipmentItem)" />
                <!--<span class="count-unit" #input>Stck.</span>
                <span class="count-blend" (click)="blendPlaceholder(equipmentItem)" *ngIf="equipmentItem.showPlaceholder">Anzahl - eingeben</span>-->
            </div>
        </div>
    </div>
</div>

<!-- start optional -->

<div class="container container-cat my-5 p-5"
    *ngFor="let equipmentCategory of _rdosService._rdosConfig.optionalEquipmentCategories">
    <div class="catNumber">{{equipmentCategory.catNumber}}</div>
    <!--<div class="item-headline" *ngIf="equipmentCategory.catName" [[innerHTML]]="equipmentCategory.catName"></div>
    <div *ngIf="equipmentCategory.catName" class="underline"></div>-->
    <div class="row subheadline">
        <div class="col" ngbTooltip="{{equipmentCategory.catTooltip}}" tooltipClass="tooltip" placement="top"
            [closeDelay]="50">
            {{equipmentCategory.catSubname}} <span class="addon">{{equipmentCategory.catSubnameAddon}}</span> <span
                *ngIf="equipmentCategory.catSubnameStar" class="highlight">*</span>
        </div>
    </div>

    <div class="row item-element" *ngFor="let equipmentItem of equipmentCategory.equipment">
        <ng-template #templateREF>
            <!--<img src="../../assets/hamburger.svg"><br>-->
            <p>{{equipmentItem.equipmentToolTip}}</p>
        </ng-template>

        <div class="col-7">
            <span [ngClass]="{'unenabled-text' : !equipmentItem.enabled}" [innerHTML]="equipmentItem.equipmentName"
                [ngbTooltip]="templateREF" tooltipClass="tooltip" placement="right" [closeDelay]="50"></span>
        </div>
        <div class="col-3 d-flex justify-content-center" *ngIf="equipmentItem.showArtNr">
            <span class="artnr" [ngClass]="{'unenabled-text' : !equipmentItem.enabled}">Art.-Nr.:
                {{equipmentItem.artNr}}</span>
        </div>
        <div class="col-2 d-flex justify-content-center" [ngClass]="{'offset-3' : !equipmentItem.showArtNr}">
            <label class="checkmark-container" *ngIf="!equipmentItem.countable && !equipmentItem.textfield">
                <input type="radio" (click)="toggle(equipmentItem,equipmentCategory)"
                    [attr.name]="equipmentCategory.catNumber" name="{{equipmentCategory.catNumber}}"
                    [attr.value]="equipmentCategory.artNr" value="{{equipmentItem.artNr}}"
                    *ngIf="equipmentCategory.selectionType=='eitherOr'" [(ngModel)]="equipmentItem.checked"
                    [attr.disabled]="!equipmentItem.enabled ? '' : null" data-md-icheck
                    (change)="toggleChecked($event)" />
                <input type="checkbox" *ngIf="equipmentCategory.selectionType=='automatic'"
                    [(ngModel)]="equipmentItem.checked" [attr.disabled]="!equipmentItem.enabled ? '' : null"
                    data-md-icheck (change)="toggleChecked($event)" />
                {{equipmentItem.selectionType}}
                <span class="checkmark" [ngClass]="{'disabled' : !equipmentItem.enabled}"></span>
            </label>
            <div *ngIf="equipmentItem.checked == true && equipmentItem.combofield == true" class="margin-combofield">
                <div class="input-wrapper textfield">
                    <input type="text" [placeholder]="equipmentItem.comboname" class="input-container" [(ngModel)]="equipmentItem.combovalue">
                </div>
            </div>
            <div class="input-wrapper" *ngIf="equipmentItem.countable && !equipmentItem.textfield">
                <input class="input-container" [(ngModel)]="equipmentItem.count"
                    placeholder="{{_rdosService._rdosConfig.enterNumber}}"
                    (ngModelChange)="modelChanged(equipmentItem)" />
            </div>

        </div>
    </div>

    <div class="row subheadline subsubheadline" *ngIf="equipmentCategory.catSubSubname">
        <div class="col">
            {{equipmentCategory.catSubSubname}} <span class="addon">{{equipmentCategory.catSubSubnameAddon}}</span>
            <span *ngIf="equipmentCategory.catSubSubnameStar" class="highlight">*</span>
        </div>
    </div>

    <div class="row item-element" *ngFor="let equipmentItem of equipmentCategory.subEquipment">
        <div class="col-7">
            <span [ngClass]="{'unenabled-text' : !equipmentItem.enabled}" [innerHTML]="equipmentItem.equipmentName"
                ngbTooltip="{{equipmentItem.equipmentToolTip}}" tooltipClass="tooltip" placement="right"
                [closeDelay]="50"></span>
        </div>
        <div class="col-3 d-flex justify-content-center" *ngIf="equipmentItem.showArtNr">
            <span class="artnr" [ngClass]="{'unenabled-text' : !equipmentItem.enabled}">Art.-Nr.:
                {{equipmentItem.artNr}}</span>
        </div>
        <div class="col-2 d-flex flex-column justify-content-center" [ngClass]="{'offset-3' : !equipmentItem.showArtNr}">
            <label class="checkmark-container" *ngIf="!equipmentItem.countable">
                <input type="checkbox" [(ngModel)]="equipmentItem.checked"
                    [attr.disabled]="!equipmentItem.enabled ? '' : null" data-md-icheck
                    (change)="toggleChecked($event)" />
                <span class="checkmark" [ngClass]="{'disabled' : !equipmentItem.enabled}"></span>
            </label>
            <div *ngIf="equipmentItem.checked == true && equipmentItem.combofield == true" class="margin-combofield">
                <div class="input-wrapper textfield">
                    <input type="text" [placeholder]="equipmentItem.comboname" class="input-container" [(ngModel)]="equipmentItem.combovalue">
                </div>
            </div>
            <div class="input-wrapper" *ngIf="equipmentItem.countable && !equipmentItem.textfield">
                <input class="input-container" [(ngModel)]="equipmentItem.count"
                    placeholder="{{_rdosService._rdosConfig.enterNumber}}"
                    (ngModelChange)="modelChanged(equipmentItem)" />
                <!--<span class="count-unit" #input>Stck.</span>
                <span class="count-blend" (click)="blendPlaceholder(equipmentItem)" *ngIf="equipmentItem.showPlaceholder">Anzahl - eingeben</span>-->
            </div>
            <div *ngIf="equipmentItem.textfield">
                <div class="input-wrapper textfield">
                    <input type="text" placeholder="{{_rdosService._rdosConfig.inputPlaceholder}}" class="input-container" [(ngModel)]="equipmentItem.text"
                        (ngModelChange)="modelChanged(equipmentItem)">
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container my-5">
    <button class="additional-btn" (click)="moreOptions()">{{_rdosService._rdosConfig.additionalBtn}}</button>
</div>
<div *ngIf="additionalOptions == true">
    <div class="container container-cat my-5 p-5" *ngFor="let equipmentCategory of _rdosService._rdosConfig.additionalEquipmentCategories; let i = index">
        <div class="catNumber">{{equipmentCategory.catNumber}}</div>
        <div class="row subheadline">
            <div class="col" ngbTooltip="{{equipmentCategory.catTooltip}}" tooltipClass="tooltip" placement="top"
                [closeDelay]="50">
                {{equipmentCategory.catSubname}} <span class="addon">{{equipmentCategory.catSubnameAddon}}</span> <span
                    *ngIf="equipmentCategory.catSubnameStar" class="highlight">*</span>
            </div>
        </div>

        <div class="row item-element" *ngFor="let equipmentItem of equipmentCategory.equipment">
            <div class="col-7">
                <span [ngClass]="{'unenabled-text' : !equipmentItem.enabled}"
                    [innerHTML]="equipmentItem.equipmentName"></span>
            </div>
            <div class="col-3 d-flex justify-content-center" *ngIf="equipmentItem.showArtNr">
                <span class="artnr" [ngClass]="{'unenabled-text' : !equipmentItem.enabled}">Art.-Nr.:
                    {{equipmentItem.artNr}}</span>
            </div>
            <div class="col-2 d-flex flex-column justify-content-center" [ngClass]="{'offset-3' : !equipmentItem.showArtNr}">
                <label class="checkmark-container" *ngIf="!equipmentItem.countable && !equipmentItem.textfield">
                    <input type="radio" (click)="toggle(equipmentItem,equipmentCategory)"
                        [attr.name]="equipmentCategory.catNumber" name="{{equipmentCategory.catNumber}}"
                        [attr.value]="equipmentCategory.artNr" value="{{equipmentItem.artNr}}"
                        *ngIf="equipmentCategory.selectionType=='eitherOr'" [(ngModel)]="equipmentItem.checked"
                        [attr.disabled]="!equipmentItem.enabled ? '' : null" data-md-icheck
                        (change)="toggleChecked($event)" />
                    <input type="checkbox" *ngIf="equipmentCategory.selectionType=='automatic'"
                        [(ngModel)]="equipmentItem.checked" [attr.disabled]="!equipmentItem.enabled ? '' : null"
                        data-md-icheck (change)="toggleChecked($event)" />
                    {{equipmentItem.selectionType}}
                    <span class="checkmark" [ngClass]="{'disabled' : !equipmentItem.enabled}"></span>
                </label>

                <div *ngIf="equipmentItem.checked == true && equipmentItem.combofield == true" class="margin-combofield">
                    <div class="input-wrapper textfield">
                        <input type="text" [placeholder]="equipmentItem.comboname" class="input-container" [(ngModel)]="equipmentItem.combovalue">
                    </div>
                </div>
                <div class="input-wrapper" *ngIf="equipmentItem.countable && !equipmentItem.textfield">
                    <input class="input-container" [(ngModel)]="equipmentItem.count"
                        placeholder="{{_rdosService._rdosConfig.enterNumber}}"
                        (ngModelChange)="modelChanged(equipmentItem)" />
                </div>
                <div *ngIf="equipmentItem.textfield">
                    <div class="input-wrapper textfield">
                        <input type="text" placeholder="{{_rdosService._rdosConfig.inputPlaceholder}}" class="input-container" [(ngModel)]="equipmentItem.text"
                            (ngModelChange)="modelChanged(equipmentItem)">
                    </div>
                </div>

            </div>
        </div>

        <div class="row subheadline subsubheadline" *ngIf="equipmentCategory.catSubSubname">
            <div class="col">
                {{equipmentCategory.catSubSubname}} <span class="addon">{{equipmentCategory.catSubSubnameAddon}}</span>
                <span *ngIf="equipmentCategory.catSubSubnameStar" class="highlight">*</span>
            </div>
        </div>

        <div class="row item-element" *ngFor="let equipmentItem of equipmentCategory.subEquipment">
            <div class="col-7">
                <span [ngClass]="{'unenabled-text' : !equipmentItem.enabled}" [innerHTML]="equipmentItem.equipmentName"
                    ngbTooltip="{{equipmentItem.equipmentToolTip}}" tooltipClass="tooltip" placement="right"
                    [closeDelay]="50"></span>
            </div>
            <div class="col-3 d-flex justify-content-center" *ngIf="equipmentItem.showArtNr">
                <span class="artnr" [ngClass]="{'unenabled-text' : !equipmentItem.enabled}">Art.-Nr.:
                    {{equipmentItem.artNr}}</span>
            </div>
            <div class="col-2 d-flex justify-content-center" [ngClass]="{'offset-3' : !equipmentItem.showArtNr}">
                <label class="checkmark-container" *ngIf="!equipmentItem.countable">
                    <input type="checkbox" [(ngModel)]="equipmentItem.checked"
                        [attr.disabled]="!equipmentItem.enabled ? '' : null" data-md-icheck
                        (change)="toggleChecked($event)" />
                    <span class="checkmark" [ngClass]="{'disabled' : !equipmentItem.enabled}"></span>
                </label>
                <div class="input-wrapper" *ngIf="equipmentItem.countable">
                    <input class="input-container" [(ngModel)]="equipmentItem.count"
                        placeholder="{{_rdosService._rdosConfig.enterNumber}}"
                        (ngModelChange)="modelChanged(equipmentItem)" />
                    <!--<span class="count-unit" #input>Stck.</span>
                <span class="count-blend" (click)="blendPlaceholder(equipmentItem)" *ngIf="equipmentItem.showPlaceholder">Anzahl - eingeben</span>-->
                </div>
            </div>
        </div>
        <button class="plus-btn" (click)="anotherContent(equipmentCategory,i)">+</button><span class="small-hint">{{_rdosService._rdosConfig.smallHint}}</span>
    </div>
</div>

<div class="container px-0">
    <h4><span class="highlight">**</span>{{_rdosService._rdosConfig.rdosHaveToSelect}}<span class="highlight">**</span>
    </h4>
    <h3>{{_rdosService._rdosConfig.rdosFooter}}</h3>
</div>

<div *ngIf="show" class="fade" [@state]="state" (@state.done)="animationDone($event)">
    <div class="offer-wrapper" #fadeableContent (click)="open(content)">
        <div class="offer-headline">{{_rdosService._rdosConfig.offerheadLine}}</div>
        <div class="offer-title">{{_rdosService._rdosConfig.offerTitle}}<img src="./../../assets/icon_download.svg"
                class="offer-download-btn"></div>
    </div>
</div>

<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{_rdosService._rdosConfig.popupHeader}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form>
            <div class="form-group">
                <!--<label for="firstname">Vorname</label>-->
                <div class="input-group">
                    <input id="company" class="form-control" placeholder="{{_rdosService._rdosConfig.enterCompany}}*"
                        name="company" [(ngModel)]="_rdosService._rdosConfig.company">
                </div>

                <div class="input-group">
                    <input id="position" class="form-control" placeholder="{{_rdosService._rdosConfig.enterPosition}}*"
                        name="position" [(ngModel)]="_rdosService._rdosConfig.position">
                </div>

                <div class="input-group">
                    <select class="form-control" [(ngModel)]="_rdosService._rdosConfig.form_of_address"
                        name="form_of_address" id="form_of_address">
                        <option value="" disabled selected hidden>{{_rdosService._rdosConfig.enterTitle}}*</option>
                        <option value="Herr">{{_rdosService._rdosConfig.selectMale}}</option>
                        <option value="Frau">{{_rdosService._rdosConfig.selectWoman}}</option>
                    </select>
                </div>

                <div class="input-group">
                    <input id="firstname" class="form-control"
                        placeholder="{{_rdosService._rdosConfig.enterFirstName}}*" name="firstname"
                        [(ngModel)]="_rdosService._rdosConfig.firstname">
                </div>

                <!--<label for="lastname">Nachname</label>-->
                <div class="input-group">
                    <input id="lastname" class="form-control" placeholder="{{_rdosService._rdosConfig.enterLastName}}*"
                        name="lastname" [(ngModel)]="_rdosService._rdosConfig.lastname">
                </div>

                <div class="input-group">
                    <input id="telephone" class="form-control"
                        placeholder="{{_rdosService._rdosConfig.enterPhoneNumber}}*" name="telephone"
                        [(ngModel)]="_rdosService._rdosConfig.telephone">
                </div>

                <div class="input-group">
                    <input id="street" class="form-control" placeholder="{{_rdosService._rdosConfig.enterStreet}}"
                        name="street" [(ngModel)]="_rdosService._rdosConfig.street">
                </div>

                <div class="input-group">
                    <input id="postal_code" class="form-control"
                        placeholder="{{_rdosService._rdosConfig.enterPostalCode}}" name="postal_code"
                        [(ngModel)]="_rdosService._rdosConfig.postal_code">
                </div>

                <div class="input-group">
                    <input id="city" class="form-control" placeholder="{{_rdosService._rdosConfig.enterCity}}"
                        name="city" [(ngModel)]="_rdosService._rdosConfig.city">
                </div>

                <div class="input-group">
                    <input id="country" class="form-control" placeholder="{{_rdosService._rdosConfig.enterCountry}}"
                        name="country" [(ngModel)]="_rdosService._rdosConfig.country">
                </div>

                <div class="input-group">
                    <input id="email" class="form-control" placeholder="{{_rdosService._rdosConfig.enterEmail}}*"
                        name="email" [(ngModel)]="_rdosService._rdosConfig.email">
                </div>

                <div>
                    <input type="checkbox" id="privacy" name="privacy" [(ngModel)]="_rdosService._rdosConfig.privacy">
                    <span class="ml-2">{{_rdosService._rdosConfig.checkPolicy1}}<a
                            href="https://www.greif-velox.com/datenschutz/">{{_rdosService._rdosConfig.checkPolicy2}}</a></span>
                </div>

                <div class="mt-2">
                    <input type="checkbox" id="newsletter" name="newsletter"
                        [(ngModel)]="_rdosService._rdosConfig.newsletter">
                    <span class="ml-2">{{_rdosService._rdosConfig.checkNewsletter}}</span>
                </div>
                <!--<label for="company">Firma</label>-->

            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark"
            (click)="checkData();">{{_rdosService._rdosConfig.send}}</button>
    </div>
</ng-template>
