import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RdosService } from '../services/rdos.service';

@Component({
  selector: 'app-later',
  templateUrl: './later.component.html',
  styleUrls: ['./later.component.scss']
})
export class LaterComponent implements OnInit {
    language;
    showOtherFlag: boolean;

    constructor(public _rdosService: RdosService,private activatedRoute: ActivatedRoute,protected readonly router: Router) { }


  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      if(params['language'] != null && params['language'] != undefined ){
        this.language = params['language']
        this.showOtherFlag = false;
        this._rdosService.setLanguage(this.language);
        this.language = this._rdosService.currentLanguage;
      }
      this.language = this._rdosService.currentLanguage;

  });  }

  scrollToElement($element): void {
    console.log($element);
    $element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }
  changeLanguage(event){
    this.language = event
    this.showOtherFlag = false;
    this.router.navigate([],{ queryParams: { language: event}})

    this._rdosService.setLanguage(this.language);
 }
 openLanguages(){
     this.showOtherFlag = true;
 }
 closeLanguages() {
    this.showOtherFlag = false;
}
}
